import React, { useEffect } from 'react'
import Countdown from 'react-countdown';
import './Countdown.scss'
import './Countdown2.scss'

export default function TimeCountdown({ target = '', type = '1' }) {
    const targetDate = new Date('2023-12-30T09:59:59').getTime(); // Set your target date and time

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a message when the countdown is completed
            return <div>Countdown is over!</div>;
        } else {
            // Render the countdown components
            return (
                <div className="clock" id={"clock" + type}>
                    <div data-value="days">{days}</div>
                    <div data-value="hours">{hours}</div>
                    <div data-value="minutes">{minutes}</div>
                    <div data-value="seconds">{seconds}</div>
                </div>
            );
        }
    };

    const renderer2 = ({ days, hours, minutes, seconds, completed }) => {

        if (completed) {
            // Render a message when the countdown is completed
            return <div>Countdown is over!</div>;
        } else {
            // Render the countdown components
            return (
                <div className="flip-clock" >
                    <span className={((days % 2) === 0) ? 'flip-clock__piece  flip' : 'flip-clock__piece _flip'}>
                        <b className="flip-clock__card card">
                            <b className="card__top">{days}</b>
                            <b className="card__bottom" data-value={Number(days) + 1} />
                            <b className="card__back" data-value={Number(days) + 1}>
                                <b className="card__bottom" data-value={Number(days)} />
                            </b>
                        </b>
                        <span className="flip-clock__slot">
                            Days
                        </span>
                    </span>

                    <span className={((hours % 2) === 0) ? 'flip-clock__piece  flip' : 'flip-clock__piece _flip'}>
                        <b className="flip-clock__card card">
                            <b className="card__top">{hours}</b>
                            <b className="card__bottom" data-value={Number(hours) + 1} />
                            <b className="card__back" data-value={Number(hours) + 1}>
                                <b className="card__bottom" data-value={Number(hours)} />
                            </b>
                        </b>
                        <span className="flip-clock__slot">
                            Hours
                        </span>
                    </span>

                    <span className={((minutes % 2) === 0) ? 'flip-clock__piece  flip' : 'flip-clock__piece _flip'}>
                        <b className="flip-clock__card card">
                            <b className="card__top">{minutes}</b>
                            <b className="card__bottom" data-value={Number(minutes) + 1} />
                            <b className="card__back" data-value={Number(minutes) + 1}>
                                <b className="card__bottom" data-value={Number(minutes)} />
                            </b>
                        </b>
                        <span className="flip-clock__slot">
                            Minutes
                        </span>
                    </span>

                    <span className={((seconds % 2) === 0) ? 'flip-clock__piece  flip' : 'flip-clock__piece _flip'}>
                        <b className="flip-clock__card card">
                            <b className="card__top">{seconds}</b>
                            <b className="card__bottom" data-value={Number(seconds) + 1} />
                            <b className="card__back" data-value={Number(seconds) + 1}>
                                <b className="card__bottom" data-value={Number(seconds)} />
                            </b>
                        </b>
                        <span className="flip-clock__slot">
                            Seconds
                        </span>
                    </span>

                </div>
            );
        }
    };

    return (
        <>
            <Countdown date={targetDate} renderer={renderer2} />
        </>
    );
};
import React from 'react'
import { useRecoilValue } from 'recoil'
import { sportDataState } from '../../Recoil/Sport/selector'
import { Box, Button, List, ListItem, ListItemText, Modal, Typography } from '@mui/material'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

function SportsAgeGroups({ sportid }) {
  const { ageGroups } = useRecoilValue(sportDataState(sportid))

  if ((!sportid) || !ageGroups) return ''

  return (
    <div >
      <Typography variant="h4" gutterBottom>
        {sportid} - Groups
      </Typography>
      <List>
        {Object.keys(ageGroups).map((gender, genderIndex) => (
          <div key={genderIndex} style={{ marginBottom: '20px' }}>
            <Typography variant="h6" gutterBottom>
              {(gender + '').toUpperCase()}
            </Typography>
            {ageGroups[gender].map((ageGroup, ageIndex) => (
              <ListItem key={ageIndex}>
                <b>{ageIndex + 1} .</b><span>{"  "}</span><ListItemText primary={ageGroup.label} />
              </ListItem>
            ))}
          </div>
        ))}
      </List>
    </div>
  )

}

export default function AgeGroups({ open, onClose, sportid }) {

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...modalStyle, width: '80%', height: '80%', overflow: 'scroll' }}>
        <SportsAgeGroups sportid={sportid} />
        <Button onClick={() => onClose()}>Close</Button>
      </Box>
    </Modal>
  )
}

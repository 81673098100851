import React from 'react';
import { Typography, Button, Container, Grid, Paper, Card, CardMedia, CardContent, Box, useMediaQuery } from '@mui/material';
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import logo from '../../Images/logo.jpeg'
import bg1 from '../../Images/bg1.jpg'
import bg2 from '../../Images/bg2.jpg'
import bg3 from '../../Images/bg3.jpg'
import bg4 from '../../Images/bg4.jpg'
import bg5 from '../../Images/bg5.jpg'
import bg6 from '../../Images/bg6.jpg'
import bg7 from '../../Images/bg7.jpg'
import para1 from '../../Images/parallel1.jpg'
import para2 from '../../Images/parallel2.png'
import sportFire from '../../Images/sport-hand.png'
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import TimeCountdown from '../../Utils/CustomComponents/Countdown/TimeCountdown';
import DirectionsRunSharpIcon from '@mui/icons-material/DirectionsRunSharp';
import SportsKabaddiSharpIcon from '@mui/icons-material/SportsKabaddiSharp';
import SkateboardingSharpIcon from '@mui/icons-material/SkateboardingSharp';
import SportsGymnasticsSharpIcon from '@mui/icons-material/SportsGymnasticsSharp';
import CircleSharpIcon from '@mui/icons-material/CircleSharp';
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp';
import PoolSharpIcon from '@mui/icons-material/PoolSharp';
import SelfImprovementSharpIcon from '@mui/icons-material/SelfImprovementSharp';
import CoronavirusSharpIcon from '@mui/icons-material/CoronavirusSharp';
import SportsBaseballSharpIcon from '@mui/icons-material/SportsBaseballSharp';
import SportsBasketballSharpIcon from '@mui/icons-material/SportsBasketballSharp';
import SportsCricketSharpIcon from '@mui/icons-material/SportsCricketSharp';
import SportsFootballSharpIcon from '@mui/icons-material/SportsFootballSharp';
import SportsHandballSharpIcon from '@mui/icons-material/SportsHandballSharp';
import SportsHockeySharpIcon from '@mui/icons-material/SportsHockeySharp';
import SportsMartialArtsSharpIcon from '@mui/icons-material/SportsMartialArtsSharp';
import SportsMmaSharpIcon from '@mui/icons-material/SportsMmaSharp';
import SportsMotorsportsSharpIcon from '@mui/icons-material/SportsMotorsportsSharp';
import SportsRugbySharpIcon from '@mui/icons-material/SportsRugbySharp';
import SportsScoreSharpIcon from '@mui/icons-material/SportsScoreSharp';
import SportsSharpIcon from '@mui/icons-material/SportsSharp';
import SportsSoccerSharpIcon from '@mui/icons-material/SportsSoccerSharp';
import SportsTennisSharpIcon from '@mui/icons-material/SportsTennisSharp';
import SportsVolleyballSharpIcon from '@mui/icons-material/SportsVolleyballSharp';
import SnowmobileSharpIcon from '@mui/icons-material/SnowmobileSharp';
import SnowshoeingSharpIcon from '@mui/icons-material/SnowshoeingSharp';
import HikingSharpIcon from '@mui/icons-material/HikingSharp';
import SailingSharpIcon from '@mui/icons-material/SailingSharp';
import DownhillSkiingSharpIcon from '@mui/icons-material/DownhillSkiingSharp';
import IceSkatingSharpIcon from '@mui/icons-material/IceSkatingSharp';
import KitesurfingSharpIcon from '@mui/icons-material/KitesurfingSharp';
import NordicWalkingSharpIcon from '@mui/icons-material/NordicWalkingSharp';
import RollerSkatingSharpIcon from '@mui/icons-material/RollerSkatingSharp';
import RowingSharpIcon from '@mui/icons-material/RowingSharp';
import SleddingSharpIcon from '@mui/icons-material/SleddingSharp';
import SnowboardingSharpIcon from '@mui/icons-material/SnowboardingSharp';
import SurfingSharpIcon from '@mui/icons-material/SurfingSharp';
import GolfCourseSharpIcon from '@mui/icons-material/GolfCourseSharp';
import KayakingSharpIcon from '@mui/icons-material/KayakingSharp';
import ParaglidingSharpIcon from '@mui/icons-material/ParaglidingSharp';
import EmojiEventsSharpIcon from '@mui/icons-material/EmojiEventsSharp';
import Diversity2SharpIcon from '@mui/icons-material/Diversity2Sharp';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import { useTheme } from '@mui/material';

const sportIconElements = [
    DirectionsRunSharpIcon, GridViewSharpIcon, CircleSharpIcon, SportsKabaddiSharpIcon, PoolSharpIcon, SelfImprovementSharpIcon,
    SportsGymnasticsSharpIcon, SkateboardingSharpIcon, SportsKabaddiSharpIcon, CoronavirusSharpIcon,

    SportsBaseballSharpIcon, SportsBasketballSharpIcon, SportsCricketSharpIcon, SportsFootballSharpIcon, SportsHandballSharpIcon,
    SportsHockeySharpIcon, SportsMartialArtsSharpIcon, SportsMmaSharpIcon, SportsMotorsportsSharpIcon, SportsRugbySharpIcon,
    SportsScoreSharpIcon, SportsSharpIcon, SportsSoccerSharpIcon, SportsTennisSharpIcon, SportsVolleyballSharpIcon, SnowmobileSharpIcon,
    SnowshoeingSharpIcon, HikingSharpIcon, SailingSharpIcon, DownhillSkiingSharpIcon, IceSkatingSharpIcon, KitesurfingSharpIcon,
    NordicWalkingSharpIcon, RollerSkatingSharpIcon, RowingSharpIcon, SleddingSharpIcon, SnowboardingSharpIcon, SurfingSharpIcon,
    GolfCourseSharpIcon, KayakingSharpIcon, ParaglidingSharpIcon

]

const calcParallex = () => {
    const s1 = Math.floor(Math.random() * 10) / 10;
    const s2 = Math.floor(Math.random() * 10) / 10 + s1;
    const scale = [s1, s2]
    const o1 = Math.floor(Math.random() * 5) / 10;
    const o2 = Math.floor(Math.random() * 5) / 10 + o1;
    const opacity = [o1, o2]
    const speed = Math.floor(Math.random() * 10);
    const tx1 = Math.floor(Math.random() * 100);
    const tx2 = Math.floor(Math.random() * 100);
    const translateX = [-tx1 + 'vw', tx2 + 'vw']
    const ty1 = Math.floor(Math.random() * 100);
    const ty2 = Math.floor(Math.random() * 100);
    const translateY = [-ty1 + 'vh', ty2 + 'vh']
    const r1 = Math.floor(Math.random() * 180);
    const r2 = Math.floor(Math.random() * 180) + r1;
    // const rotate = [-r1, r2]
    const rotate = [-r1, r2]
    return { speed, scale, opacity, translateX, translateY, rotate }
}

const Home = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>

            <Paper
                sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ParallaxBanner
                    style={{ height: '100%' }}
                    layers={[
                        {
                            image: para1,
                            speed: -20,
                        },
                        {
                            children: (
                                <Box sx={{ position: 'absolute', top: '35%', width: '100%' }}>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        sx={{
                                            width: '90vw',
                                            margin: '0 auto',
                                            textAlign: 'center',
                                            background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            background: '#C9FFBF',  /* fallback for old browsers */
                                            background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                            background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        Welcome back to
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        gutterBottom
                                        sx={{
                                            width: '90vw',
                                            margin: '0 auto',
                                            textAlign: 'center',
                                            background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            background: '#C9FFBF',  /* fallback for old browsers */
                                            background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                            background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        Vysya Olympiks
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        sx={{
                                            width: '90vw',
                                            margin: '0 auto',
                                            textAlign: 'center',
                                            background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            background: '#C9FFBF',  /* fallback for old browsers */
                                            background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                            background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        2023
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        sx={{
                                            width: '90vw',
                                            margin: '0 auto',
                                            textAlign: 'center',
                                            background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            background: '#C9FFBF',  /* fallback for old browsers */
                                            background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                            background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        Powered by the Vision and Spirit of TNAVMSYW
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        sx={{
                                            width: '90vw',
                                            margin: '0 auto',
                                            textAlign: 'center',
                                            background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            background: '#C9FFBF',  /* fallback for old browsers */
                                            background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                            background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                            fontWeight: 'bolder',
                                        }}
                                    >
                                        @<br />Thiruvannamalai
                                    </Typography>
                                </Box>
                            ),
                            speed: -15,
                        },
                        {
                            image: sportFire,
                            speed: 70,
                            rotate: [60, 0],
                            style: { width: '140px', height: '140px', top: '60%', left: '60%' },
                            scaleX: [-1, -1],
                            translateY: ['40%', 0]
                        },
                        {
                            image: para2,
                            speed: -10,
                        },
                    ]}
                >
                </ParallaxBanner>
            </Paper>


            <Paper
                sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#556270',  /* fallback for old browsers */
                    background: '-webkit-linear-gradient(to left, #FF6B6B, #556270)',  /* Chrome 10-25, Safari 5.1-6 */
                    background: 'linear-gradient(to left, #FF6B6B, #556270)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                    margin: '1% auto'
                }}
            >
                <Container maxWidth="md" sx={{ textAlign: 'center', }}>
                    <Parallax speed={10}>
                        <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                background: '-webkit-linear-gradient(45deg, #09009f,#00ff95  80%)',
                                // background: 'rgb(63, 94, 251)',
                                // background: 'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                background: '#C9FFBF',  /* fallback for old browsers */
                                background: '-webkit-linear-gradient(to left,  #C9FFBF,#FFAFBD)',  /* Chrome 10-25, Safari 5.1-6 */
                                background: 'linear-gradient(to left,  #C9FFBF,#FFAFBD)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                fontWeight: 'bolder',
                                textAlign: 'justify',
                            }}
                        >
                            Join us on December 30th and 31st, 2023, for the 4th iteration of Vysya Olympiks at Tiruvannamalai —an exhilarating celebration of sports and community.
                        </Typography>
                    </Parallax>
                    <Parallax speed={5}>
                        <Button variant="contained" color="primary">
                            Registeration Starts Soon
                        </Button>
                    </Parallax>
                    <Box sx={{ margin: '20px auto', display: 'flex', justifyContent: 'center' }}>
                        <AddToCalendarButton
                            name="VYSYA OLYMPIKS"
                            description="Don't miss out on the fun!"
                            startDate="2023-12-30"
                            startTime="10:00"
                            endDate="2023-12-31"
                            endTime="20:00"
                            timeZone="Asia/Calcutta"
                            location="TIRUVANNAMALAI"
                            organizer="TAMIL NADU ARYA VYSYA MAHA SABHA YOUTH WING|ywolympiks2023@gmail.com"
                            availability="free"
                            options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo', 'Microsoft365', 'MicrosoftTeams']}
                            listStyle="dropdown-static"
                            buttonStyle="date"
                            trigger="click"
                            hideIconButton
                            buttonsList
                            size="5"
                            label="VYSYA OLYMPIKS"
                            lightMode="dark"
                        ></AddToCalendarButton>
                    </Box>
                    {/* </Parallax> */}
                </Container>
            </Paper>

            <Paper
                sx={{
                    minHeight: '100vh',
                    backgroundImage: `url("${logo}")`, // Replace with your hero image URL
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#4b6cb7',  /* fallback for old browsers */
                    background: ' -webkit-linear-gradient(to left, #182848, #4b6cb7)',  /* Chrome 10-25, Safari 5.1-6 */
                    background: 'linear-gradient(to left, #182848, #4b6cb7)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

                    // background: '#000046',  /* fallback for old browsers */
                    // background: '-webkit-linear-gradient(to left, #1CB5E0, #000046)',  /* Chrome 10-25, Safari 5.1-6 */
                    // background: 'linear-gradient(to left, #1CB5E0, #000046)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                }}
            >
                <Container maxWidth="md" sx={{ textAlign: 'center', }}>
                    <Parallax speed={20}>
                        <Typography variant="h5" paragraph sx={{ textAlign: 'justify', color: 'white', marginTop: '15%' }}>
                            Vysya Olympiks is a prestigious sports event that has seen immense success in its past editions. This year, we are excited to continue the tradition with an even grander event.
                        </Typography>
                    </Parallax>
                    <Grid container spacing={2} sx={{ color: '#FFF' }}>
                        <Grid item xs={12} sm={4}>
                            <Parallax speed={15} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box textAlign="center" >
                                    <EmojiEventsSharpIcon sx={{ fontSize: '4rem' }} />
                                </Box>
                                <Box textAlign="center" sx={{ padding: '5px' }}>
                                    <Typography variant="h5">9+</Typography>
                                    <Typography variant="body1">Sports Events</Typography>
                                </Box>
                            </Parallax>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Parallax speed={10} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box textAlign="center" >
                                    <Diversity2SharpIcon sx={{ fontSize: '4rem', }} />
                                </Box>
                                <Box textAlign="center" sx={{ padding: '5px' }}>
                                    <Typography variant="h5">3000+</Typography>
                                    <Typography variant="body1"> Participants</Typography>
                                </Box>
                            </Parallax>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Parallax speed={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box textAlign="center" >
                                    <LanguageSharpIcon sx={{ fontSize: '4rem' }} />
                                </Box>
                                <Box textAlign="center" sx={{ padding: '5px' }}>
                                    <Typography variant="h5">World Record</Typography>
                                    <Typography variant="body1">Attempt</Typography>
                                </Box>
                            </Parallax>
                        </Grid>
                        {/* <Grid item xs={12} sm={4}>
                            <Parallax speed={5}>
                                <Box textAlign="center">
                                    <Typography variant="h5">2 Days</Typography>
                                    <Typography variant="body1">Event Duration</Typography>
                                </Box>
                            </Parallax>
                        </Grid> */}
                    </Grid>
                    <Parallax speed={-10} scale={[0, 0.75]}>
                        <CardMedia
                            component="img"
                            // height="350"
                            image={logo}
                            alt="Vysya Olympiks"
                            width={'100%'}
                            height={'100%'}
                        />
                    </Parallax>

                    <CardContent sx={{ display: { md: 'block', xs: 'none', } }}>
                        {
                            !isMobile ?
                                <Box
                                    // display="flex"
                                    position={'relative'}
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    flexDirection={'row'}
                                    color={'#fff'}
                                >
                                    {
                                        sportIconElements.map((SportIcon, index) => {
                                            return <Box key={index} textAlign="center" mx={2} position={'absolute'} left={Math.floor(Math.random() * 80) + '%'}>
                                                <Parallax {...calcParallex()}>
                                                    <SportIcon fontSize='large' />
                                                </Parallax>
                                            </Box>
                                        })
                                    }
                                </Box>
                                : ''
                        }
                    </CardContent>
                </Container>
            </Paper>

            <Container maxWidth="lg">
                {/* <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            background: '-webkit-linear-gradient(45deg, #09009f, #00ff95 80%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        Welcome to Vysya Olympiks 2023
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
                        Join us on December 30th and 31st, 2023, for the 4th iteration of Vysya Olympiks—an exhilarating celebration of sports and community.
                    </Typography>

                    Image slideshow or parallax component here

                    <Button variant="contained" color="primary">
                        Register Now
                    </Button>
                </Paper> */}

                {/* About Vysya Olympiks Section */}
                {/* <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>

                    <Typography variant="h5" gutterBottom>
                        About Vysya Olympiks
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
                        Vysya Olympiks is a prestigious sports event that has seen immense success in its past editions. This year, we are excited to continue the tradition with an even grander event.
                    </Typography>

                    Image gallery of past events
                </Paper> */}

                {/* Sponsors Section */}
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Our Sponsors
                    </Typography>
                    {/* Logos and names of sponsors */}
                </Paper>

                {/* Meet the Officials Section */}
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Meet the Officials
                    </Typography>
                    {/* Images and names of officials */}
                </Paper>

                {/* Event Details Section */}
                {/* <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Event Details
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Get ready for nine exciting sports at Vysya Olympiks 2023. Click below to explore each sport in detail.
                    </Typography>
                    Links or buttons to each sport's details
                </Paper> */}

                {/* Registration and Application Section */}
                {/* <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Registration and Application
                    </Typography>
                    Call to action buttons for registration and application status
                </Paper> */}

                {/* Testimonials Section */}
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Testimonials
                    </Typography>
                    {/* Quotes or testimonials from participants, officials, and sponsors */}
                </Paper>

                {/* Countdown Timer Section */}
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }} id='countdown'>
                    <Typography variant="h5" gutterBottom>
                        Countdown to Vysya Olympiks 2023
                    </Typography>
                    {/* Countdown timer */}
                    <TimeCountdown />
                </Paper>

                {/* Contact Information Section */}
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Contact Information
                    </Typography>
                    {/* Contact details and a contact form */}
                </Paper>

                {/* Social Media Links Section */}
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Follow Us on Social Media
                    </Typography>
                    {/* Links to your social media profiles */}
                </Paper>
            </Container>
        </>
    );
};

export default Home;

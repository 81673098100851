import { useRecoilValue } from 'recoil'
import { fauth } from '../../Utils/Firebase/firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import { loggedInState } from '../../Recoil/Auth/selector'
import { authUserState } from '../../Recoil/Auth/atom'
import { useEffect } from 'react'

export default function useAuth() {
    const authUser = useRecoilValue(authUserState)
    const isLoggedIn = useRecoilValue(loggedInState)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
    }, [authUser])

    const login = () => {
        return navigate('/login')
        const { pathname, search } = location
        if (search.includes('authenticate=true')) return;
        const isslash = pathname.endsWith('/')
        const issearch = search.startsWith('?')
        navigate(`${location.pathname}${isslash ? '' : '/'}${issearch ? `${search}&authenticate=true` : '?authenticate=true'}`)
    }

    const withAuth = (fn) => {
        return async (...args) => {
            if (isLoggedIn) {
                return await fn(...args);
            } else {
                login();
            }
        };

        // if (isLoggedIn)
        //     return (...args) => fn(...args)
        // login()
    }

    const forceLogout = () => {
        return fauth.signOut()
    }

    const logout = async () => {
        try {
            return await fauth.signOut()
        } catch (err) {
            console.error({ err });
        }
    }

    return { isLoggedIn, uid: authUser.uid, authUser, login, logout, forceLogout, withAuth }
}

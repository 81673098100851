import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { sportDataState } from '../../Recoil/Sport/selector'

export default function CreateFields({ fields = [], formik, sportId, registrationData }) {
    const sportData = useRecoilValue(sportDataState(sportId))

    const createField = ({ field, name, fprops = {}, ...rest }) => {
        const { values, handleChange, touched, errors, handleBlur } = formik

        switch (field) {
            case 'nodeFunc': {
                return rest.nodeFunc(formik, sportId, sportData, registrationData)
            }
            case 'checkbox': {
                return <FormControl error={touched[name] && Boolean(errors[name])}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values[name]}
                                onChange={handleChange}
                                name={name}
                                onBlur={handleBlur}
                            />
                        }
                        label={fprops.label}
                    />
                    <FormHelperText>
                        {touched[name] && errors[name]}
                    </FormHelperText>
                </FormControl>
            }
            case 'radio': {
                return <FormControl error={touched[name] && Boolean(errors[name])}>
                    <FormLabel id="demo-radio-buttons-group-label">{fprops.label}</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name={name}
                        row
                        value={values[name]}
                        onChange={handleChange}
                    >
                        {
                            (fprops.options).map(option => {
                                return <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                            })
                        }
                    </RadioGroup>
                    <FormHelperText>
                        {touched[name] && errors[name]}
                    </FormHelperText>
                </FormControl>
            }
            case 'select': {
                return <FormControl fullWidth error={touched[name] && Boolean(errors[name])}>
                    <InputLabel id="district-select-label">{fprops.label}</InputLabel>
                    <Select
                        labelId="district-select-label"
                        id="demo-simple-select"
                        value={values[name]}
                        label={fprops.label}
                        onChange={handleChange}
                        name={name}
                    >
                        {
                            (fprops.options).sort((a, b) => (a > b) ? 1 : ((a < b) ? -1 : 0)).map(option => {
                                return <MenuItem key={option} value={option}>{option}</MenuItem>
                            })
                        }
                        {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                    <FormHelperText>
                        {touched[name] && errors[name]}
                    </FormHelperText>
                </FormControl>
            }
            default: {
                return <TextField
                    margin='normal'
                    {...fprops}
                    fullWidth
                    name={name}
                    id={name}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[name] && Boolean(errors[name])}
                    helperText={touched[name] && errors[name]}
                    autoComplete='off'
                />
            }
        }
    }

    return (
        <>
            {
                fields.map((field, index) => {
                    return <React.Fragment key={field.name + index + ''}>
                        {createField(field)}
                    </React.Fragment>
                })
            }
        </>
    )
}

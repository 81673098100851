import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Parallax, ParallaxBanner } from 'react-scroll-parallax'

import vyoLogo from '../../Images/vyo2023-tb.png'
import tnavmsywLogo from '../../Images/logos/tnavmsyw-logo-tbg.png';
import tnavmsLogo from '../../Images/logos/tnavms-logo-tbg.png';
import efactLogo from '../../Images/logos/edu-fed-logo-tbg.png';

const logoStyle = {
    width: '100%',
    height: '75%',
    objectFit: 'contain', // Maintain aspect ratio and fit within the specified dimensions
};

export default function By() {
    return (
        <Container maxWidth="lg" sx={{ minHeight: '100vh' }}>
            {/* <Box sx={{ position: 'relative',}}> */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        width: '90vw',
                        margin: '0 auto',
                        textAlign: 'center',
                        background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        background: '#C9FFBF',  /* fallback for old browsers */
                        background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                        background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                        fontWeight: 'bolder',
                        fontSize: '2vw'
                    }}
                >
                    A Soulful Community Initiative
                </Typography>
                {/* </Box> */}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <img src={vyoLogo} alt='vy-olympiks-2023'
                    style={{ width: '80%', }} />
            </Box>
            <Typography
                variant="h4"
                gutterBottom
                sx={{
                    // width: '90vw',
                    margin: '0 auto',
                    textAlign: 'center',
                    background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                    fontWeight: 'bolder',
                    fontSize: '3vw'
                }}
            >
                TAMILNADU ARYA VYSYA MAHASABA <br />
                TAMILNADU ARYA VYSYA MAHASABA YOUTHWING<br />
                ARYA VYSYA CHARITABLE AND EDUCATION FOUNDATION<br />
                THIRUVANNAMALAI ARYA VYSYA SAMAJAM<br />
            </Typography>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12} sm={4}>
                    {/* <Parallax speed={0} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
                    <img src={tnavmsLogo} alt="TNAVMS Logo" width="75%" style={logoStyle} />
                    {/* </Parallax> */}
                    {/* <Typography variant="subtitle2" align="center" sx={{ mt: 1 }}>
                                TNAVMS
                            </Typography> */}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Parallax speed={15} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={tnavmsywLogo} alt="TNAVMSYW Logo" width="75%" style={logoStyle} />
                    </Parallax>
                    {/* <Typography variant="subtitle2" align="center" sx={{ mt: 1 }}>
                                TNAVMSYW
                            </Typography> */}
                </Grid>
                <Grid item xs={12} sm={4}>
                    {/* <Parallax speed={-15} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
                    <img src={efactLogo} alt="EFACT Logo" width="100%" style={logoStyle} />
                    {/* </Parallax> */}
                    {/* <Typography variant="subtitle2" align="center" sx={{ mt: 1 }}>
                                EFACT
                            </Typography> */}
                </Grid>
            </Grid>
        </Container>
    )
}

import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'
import TimeCountdown from '../../Utils/CustomComponents/Countdown/TimeCountdown'
import { Container, Paper, Typography } from '@mui/material'

export default function CoutndownSection() {
    return (
        <Container maxWidth="lg">
            {/* <Paper elevation={3} sx={{ p: 3, mt: 3 }}> */}
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }} id='countdown'>
                <Typography variant="h5" gutterBottom align='center'>
                    Countdown to Vysya Olympiks 2023
                </Typography>
                {/* Countdown timer */}
                <TimeCountdown />
                {/* </Paper> */}
            </Paper>
        </Container>
    )
}

import React, { useEffect } from 'react';
import './ShapesLoader.scss'

const ShapesLoader = () => {

    useEffect(() => {
        const types = [
            'circle', 'semi-circle', 'square', 'triangle', 'triangle-2', 'rectangle',
            'oval', 'oval-2', 'egg'
        ];
        const colors = [
            '#836ee5', '#fe94b4', '#49d2f5', '#ff5354', '#00b1b4', '#ffe465', '#0071ff', '#03274b', '#34568B', '#6B5B95', '#92A8D1', '#F7CAC9', '#009B77', '#EFC050', '#DFCFBE', '#C3447A',
            '#B55A30', '#F5DF4D', '#0072B5', '#A0DAA9', '#4B5335', '#FA7A35', '#EDF1FF', '#C39EA0', '#FA255E', '#F8E5E5', '#C39EA0', '#FF652F', '#272727', '#747474', '#FFE400', '#14A76C',
            '#10EE67', '#FECB00', '#F5B842', '#EFC7B7', '#BAB2B5', '#EEE2DC', '#123C69', '#AC3B61', '#dddfd4', '#fae596', '#3fb0ac', '#173e43', '#bccbde', '#c2dde6', '#431c5d', '#e6e9f0',
            '#cdd422',
            '#836ee5', '#fe94b4', '#49d2f5', '#ff5354', '#00b1b4', '#ffe465', '#0071ff', '#03274b', '#34568B', '#6B5B95', '#92A8D1', '#F7CAC9', '#009B77', '#EFC050', '#DFCFBE', '#C3447A',
            '#B55A30', '#F5DF4D', '#0072B5', '#A0DAA9', '#4B5335', '#FA7A35', '#EDF1FF', '#C39EA0', '#FA255E', '#F8E5E5', '#C39EA0', '#FF652F', '#272727', '#747474', '#FFE400', '#14A76C',
            '#10EE67', '#FECB00', '#F5B842', '#EFC7B7', '#BAB2B5', '#EEE2DC', '#123C69', '#AC3B61', '#dddfd4', '#fae596', '#3fb0ac', '#173e43', '#bccbde', '#c2dde6', '#431c5d', '#e6e9f0',
            '#cdd422',
        ];
        const mix_blend = ['darken', 'multiply', 'overlay', 'plus-lighter', 'soft-light', 'color', 'color-burn', 'color-dodge']

        const intervalId = [];

        const generateRandomStyles = (shape) => {
            const cl = shape.classList;
            shape.className = '';

            // Assign styles
            cl.add(types[Math.floor(Math.random() * types.length)]);
            const offset = (Math.random() * 4) - 2;
            const opp = offset >= 0 ? '+ ' : '- ';
            const styles = [
                ['left', `calc(50% ${opp} ${offset}vw)`],
                ['--bounce-variance', `${(Math.random() * 20) - 10}vh`],
                ['--base_scale', `${(Math.random() * 6) + 4}vh`],
                ['--rotation', `${(Math.random() * 180) - 90}deg`],
                ['--color', colors[Math.floor(Math.random() * colors.length)]],
                ['--mix-blend', mix_blend[Math.floor(Math.random() * mix_blend.length)]],
            ];
            styles.forEach(style => shape.style.setProperty(style[0], style[1]));

            // Animate
            if (!cl.contains('bounce-up')) cl.add('bounce-up');
            cl.replace('bounce-down', 'bounce-up');
            setTimeout(() => cl.replace('bounce-up', 'bounce-down'), 400);
        };

        const shapes = document.querySelectorAll('.loading-shape');

        shapes.forEach((shape, index) => {
            intervalId[index] = setInterval(() => {
                generateRandomStyles(shape);
            }, 740);
        });

        return () => {
            intervalId.forEach(id => clearInterval(id));
        };
    }, []);

    return (
        <div
            className='shapes-loader'
        // style={{ position: 'relative', width: '100%', height: '100%' }}
        >
            {Array.from({ length: 3 }, (_, index) => (
                <span key={index} className="loading-shape" style={{ position: 'absolute', left: '50%', top: '50%' }} />
            ))}
        </div>
    );
};

export default ShapesLoader;

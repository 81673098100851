import React from 'react'
import { calculateAgeGroup } from './ageGroupCalc'
import { List, ListItem, ListItemText, Typography } from '@mui/material'

export default function ShopAgeGroupData({ sportid, sport, userBirthdate, gender = 'male' }) {

    if (sportid === 'Kabaddi')
        return ''

    const ageGroupData = calculateAgeGroup(sport, userBirthdate, gender)

    return (
        <div>
            {
                sport.evaluation ?
                    <>
                        <List>
                            <Typography variant="h6" gutterBottom>
                                Evaluation
                            </Typography>
                            <Typography >{sport.evaluation.title}</Typography>
                            {
                                sport.evaluation.list.map((li, index) => {
                                    return <ListItem key={index}>
                                        <ListItemText
                                            primary={
                                                <div>
                                                    <span style={{ fontWeight: 'bold' }}>{index + 1}. {li.title}: </span>
                                                    {li.content}
                                                </div>
                                            }
                                        />
                                    </ListItem>
                                })
                            }
                        </List>
                    </>
                    : ''
            }
            {
                (sport.showList && ageGroupData[sport.showList]) ?
                    <>
                        <List>
                            <Typography variant="h6" gutterBottom>
                                {(sport.showList + '').toUpperCase()} List
                            </Typography>
                            {
                                ageGroupData[sport.showList].map((li, index) => {
                                    return <ListItem key={li}>
                                        <b>{index + 1} .</b><span>{"  "}</span><ListItemText primary={li} />
                                    </ListItem>
                                })
                            }
                        </List>
                    </>
                    : ''
            }
            <Typography gutterBottom>
                Your Age Group : {ageGroupData.label}
            </Typography>
        </div>
    )
}

import { Paper, Typography } from '@mui/material'
import React from 'react'

export default function Sponsors() {
    return (
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h5" gutterBottom>
                Our Sponsors
            </Typography>
            {/* Logos and names of sponsors */}
        </Paper>
    )
}

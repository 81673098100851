import { Box, Button, Modal } from '@mui/material'
import React, { useState } from 'react'
import TAC from './TAC'

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}

export default function TACModal({ sportid, open, onClose, onlysport = false, openbutton = false }) {
    const [opentac, setOpentac] = useState(false)

    const close = () => {
        if (onClose) return onClose()
        setOpentac(false)
    }

    return (
        <>
            <Modal
                open={open || opentac}
                onClose={() => close()}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...modalStyle, width: '80%', height: '80%', overflow: 'scroll' }}>
                    <TAC sportid={sportid} onlysport={onlysport} />
                    <Button onClick={() => close()}>Close</Button>
                </Box>
            </Modal>
            {
                openbutton ?
                    <span><Button size="small" onClick={() => setOpentac(true)} >Click Here To View</Button></span>
                    : ''
            }
        </>
    )
}

import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { sportDataState } from '../../Recoil/Sport/selector'
import { donwDataState, generalTACState } from '../../Recoil/Sport/atom'
import { List, ListItem, ListItemText, Typography, Tooltip, IconButton } from '@mui/material'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import FormDownload from '../Account/FormDownload/FormDownload'

function GeneralTAC() {
    const termsAndConditions = useRecoilValue(generalTACState)

    const setDownData = useSetRecoilState(donwDataState)

    const download = () => setDownData({ tacfor: 'General' })

    return (
        <div >
            <Typography variant='h4'>
                General Terms & Conditions
                <Tooltip title="Download">
                    <IconButton onClick={download}>
                        <DownloadOutlinedIcon color='info' />
                    </IconButton>
                </Tooltip>
            </Typography>
            <List>
                {
                    termsAndConditions.map((term, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>{index + 1}. {term.title}: </span>
                                        {term.content}
                                    </div>
                                }
                            />
                        </ListItem>
                    ))
                }
            </List>
        </div>
    )
}

function SportTAC({ sportid }) {
    const { tac } = useRecoilValue(sportDataState(sportid))
    const setDownData = useSetRecoilState(donwDataState)

    const download = () => setDownData({ tacfor: sportid })

    if (!tac) return ''
    return (
        <div >
            <Typography variant='h4'>
                Sport-Specific Terms and Conditions ({sportid})
                <Tooltip title="Download">
                    <IconButton onClick={download}>
                        <DownloadOutlinedIcon color='info' />
                    </IconButton>
                </Tooltip>
            </Typography>
            <List>
                {
                    tac.map((term, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>{index + 1}. {term.title}: </span>
                                        {term.content}
                                    </div>
                                }
                            />
                        </ListItem>
                    ))
                }
            </List>
        </div>
    )
}

export default function TAC({ sportid, onlysport = false }) {
    return (
        <>
            {
                sportid ? <SportTAC sportid={sportid} /> : ''
            }
            {
                onlysport ? '' :
                    <GeneralTAC />
            }
            <FormDownload />
        </>
    )
}

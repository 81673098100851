import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import 'firebaseui/dist/firebaseui.css'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    clientId: process.env.REACT_APP_CLIENT_ID
};

const app = initializeApp(config)
const fdb = getFirestore(app)
const fstorage = getStorage(app)
const fauth = getAuth(app)

export { app, fdb, fstorage, fauth }

import React from 'react'
import ShapesLoader from '../../Utils/CustomComponents/Loading/ShapesLoader/ShapesLoader'
import { Box, Button, Paper, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SportsTennisSharpIcon from '@mui/icons-material/SportsTennisSharp';

export default function RegisterationStartsSoon() {
    const navigate = useNavigate()

    const { sportId } = useParams()

    return (
        <Paper
            sx={{
                minHeight: '100vh',
                background: 'rgb(0,0,0,0.15)'
            }}
        >
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <ShapesLoader />
                {/* <div className="starts-soon">
                    {sportId} registeration Starts Soon ...
                </div> */}

                <Box
                    sx={{
                        // backgroundColor: '#f0f0f0', 
                        padding: '40px 0', // Vertical and horizontal padding
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="body1" gutterBottom>
                        Get ready to join the Vysya Olympiks 2023 event!
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        {sportId} Registration Starts Soon
                    </Typography>
                    <EventAvailableIcon
                        sx={{
                            fontSize: 80, // Icon size
                            color: 'primary.main', // Icon color
                            marginBottom: '20px', // Spacing below the icon
                        }}
                    />
                    <Typography variant="body2" gutterBottom>
                        Mark your calendars and don't miss out on this exciting event.
                    </Typography>
                </Box>

                <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button
                        // component={Link} // Use Link component for page redirection
                        // to="/home" // Replace with the actual path to your home page
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate('/')}
                        startIcon={<HomeSharpIcon />}
                        sx={{ margin: '10px' }}
                    >
                        Home Page
                    </Button>
                    <Button
                        // component={Link} // Use Link component for page redirection
                        // to="/home" // Replace with the actual path to your home page
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate('/sports')}
                        startIcon={<SportsTennisSharpIcon />}
                        sx={{ margin: '10px' }}
                    >
                        Sports Page
                    </Button>
                </Box>
            </div>
        </Paper>
    )
}

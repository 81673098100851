import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDoc, doc } from 'firebase/firestore'
import { fdb } from '../../Utils/Firebase/firebase'
import useQuery from '../../CustomHooks/useQuery'
import { getLenOfObjBool } from '../../Utils/General/objectManipulation'
import Loading from '../../Utils/CustomComponents/Loading/Loading'
import { Button, Container, Box, Typography } from '@mui/material'

export default function WRPayment() {
    const ai = useQuery('ai')
    const navigate = useNavigate()

    const [parti, setParti] = useState({})

    useEffect(() => {

        const getData = async () => {
            console.log({ ai });
            if (!ai) return
            try {
                const aid = parseInt(ai, 36) + ''
                if ((aid + '').length !== 12)
                    return
                const pa = getDoc(doc(fdb, 'participents', aid))
                if ((await pa).exists()) {
                    const data = (await pa).data()
                    console.log({ data });
                    return setParti(data)
                }
                console.log('no found');
                // return navigate('/')
            } catch (error) {
                console.error({ error });
                // navigate('/')
            }

        }

        getData()

    }, [])

    if (getLenOfObjBool(parti)) {
        const { censusData: { censusid, aadhar }, personalData: { name } } = parti

        const upiURL = `upi://pay?pa=broadwayfinancialservicecentre@sbi&pn=VysyaOlympiks&cu=INR&am=999.00&tn=${censusid}&tid=${ai}`

        const confirmationText = `Hi Vysya Olympiks Team 🎉, 

This is *${(name + '').trim()}*

I have successfully made a payment 💰 for *Vysya Olympiks*.

Here is my payment confirmation screenshot.

My App ID : *${Number(aadhar).toString(36)}*
My Census ID : *${censusid}*

Thank You.

Best Regards,
*${(name + '').trim()}*
`

        const sendScreenShot = () => {
            const replayURL = `https://wa.me/+91${'9965934585'}?text=${encodeURIComponent(confirmationText)}`
            window.open(replayURL, '_blank')
        }

        return (
            <Container
                maxWidth="lg"
                sx={{
                    padding: '24px',
                }}
            >
                <Box
                    sx={{
                        // display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}
                >
                    <Typography variant="h4" >
                        Hi {name}
                    </Typography>
                    <Typography variant="h6" >
                        Thank for showing interest to participate in World Record.
                    </Typography>

                    <br />
                    <br />
                    {
                        parti.certpaid ?
                            <Typography variant="body1" >
                                You paid for your certificate. Congratulations 🎉
                            </Typography>
                            :
                            <>

                                <Typography variant="body1" >
                                    <Button onClick={() => window.open(upiURL, '_blank')}>Click Here</Button> to Pay For Your Certificate.
                                    <br />
                                    or
                                    <br />
                                    Scan Me
                                </Typography>
                                <img src={`https://api.qrserver.com/v1/create-qr-code/?size=450x450&data=${encodeURIComponent(upiURL)}`} alt='Scan-Me'
                                    style={{ width: '50%', }}
                                />
                            </>
                    }
                    <br />
                    <br />
                    <Typography>
                        After a successful payment, please take a screenshot of the confirmation and send it by <Button onClick={sendScreenShot} >Clicking Here</Button>. This step is crucial to confirm your payment and secure your world record attempt.
                    </Typography>
                </Box>
            </Container>
        )
    }

    return <Loading loading />
}

import { Container, Paper, Step, StepButton, StepContent, StepLabel, Stepper, Typography, Button } from '@mui/material'
import React, { useMemo, useState } from 'react'
import EachStep from './EachStep'
import { useParams } from 'react-router-dom'
import notify from '../../Utils/CustomComponents/Notify/notify'
import { doc, setDoc } from 'firebase/firestore'
import { fdb } from '../../Utils/Firebase/firebase'
import useAuth from '../Auth/useAuth'
import { useNavigate } from 'react-router-dom';

const defaultDataForForm = {
    data: {},
    activeStep: 0,
    completedSteps: new Set(),
    failedSteps: new Set(),
    skippedSteps: new Set(),
    workid: Math.floor(Math.random() * 876543210)
}

export default function RegistrationForm2({
    oldData = defaultDataForForm,
    resetOldData,
}) {

    const navigate = useNavigate()

    const { sportId } = useParams()
    const { uid } = useAuth()

    const [steps, setSteps] = useState([
        { name: 'Step 1: eCensus Validation' },
        { name: 'Step 2: Personal Information' },
        { name: 'Step 3: Emergency Contact' },
        { name: 'Step 4: World Record' },
        { name: 'Step 5: Accommodation', },
        { name: 'Step 6 : Sport Form' },
        { name: 'Step 7: Sport Achievement', optional: true },
        { name: 'Step 8: Agree T&C and Submit' },
        // { name: 'Step 7: Submit Registration' },
    ])

    const [activeStep, setActiveStep] = useState(oldData.activeStep ?? defaultDataForForm.activeStep)
    const [skippedSteps, setSkippedSteps] = useState(new Set(oldData?.skippedSteps ?? defaultDataForForm.skippedSteps.values()))
    const [completedSteps, setCompletedSteps] = useState(new Set(oldData?.completedSteps ?? defaultDataForForm.completedSteps.values()))
    const [failedSteps, setFailedSteps] = useState(new Set(oldData?.failedSteps ?? defaultDataForForm.failedSteps.values()))
    const workid = useMemo(() => oldData.workid ?? defaultDataForForm.workid, [])

    const [registrationData, setRegistrationData] = useState({ ...oldData.data })


    const resetAllData = (step = true) => {
        if (step)
            setActiveStep(defaultDataForForm.activeStep)
        setSkippedSteps(defaultDataForForm.skippedSteps)
        setCompletedSteps(defaultDataForForm.completedSteps)
        setFailedSteps(defaultDataForForm.failedSteps)
        resetOldData()
    }

    const updateDataToDB = async (astep, values, additionalData = {}) => {
        await setDoc(
            doc(fdb, 'users', uid, 'registration', sportId),
            {
                data: { ...(additionalData ?? {}), [astep]: { ...values } },
                skippedSteps: Array.from(skippedSteps),
                completedSteps: Array.from(completedSteps),
                failedSteps: Array.from(failedSteps),
                workid,
                activeStep,
            },
            { merge: 'true' }
        )
        return true;
    }


    const isStepOptional = (astep = activeStep) => {
        return !!steps[astep]?.optional
    }

    const isStepSkipped = (astep = activeStep) => {
        return skippedSteps.has(astep)
    }

    const isStepCompleted = (astep = activeStep) => {
        return completedSteps.has(astep)
    }

    const isStepFailed = (astep = activeStep) => {
        return failedSteps.has(astep)
    }

    const handleSkip = () => {
        if (!isStepOptional()) {
            return notify('e', "You can't skip a step that isn't optional.")
        }
        setActiveStep(prevState => prevState + 1)
        setSkippedSteps(prevState => {
            const newSkippedStep = new Set(prevState.values())
            newSkippedStep.add(activeStep)
            return newSkippedStep
        })
        return;
    }

    const handleNext = () => {
        if (isStepSkipped())
            setSkippedSteps(prevState => {
                const newSkippedStep = new Set(prevState.values())
                newSkippedStep.delete(activeStep)
                return newSkippedStep
            })
        if (isStepFailed())
            setFailedSteps(prevState => {
                const newFailedStep = new Set(prevState.values())
                newFailedStep.delete(activeStep)
                return newFailedStep
            })
        if (!isStepCompleted())
            setCompletedSteps(prevState => {
                const newCompletedStep = new Set(prevState.values())
                newCompletedStep.add(activeStep)
                return newCompletedStep
            })
        setActiveStep(prevState => prevState + 1)
    }

    const handleAnotherRegisteation = () => {
        setCompletedSteps(prevState => {
            const newCompletedStep = new Set(prevState.values())
            newCompletedStep.add(activeStep)
            newCompletedStep.add(1)
            newCompletedStep.add(2)
            newCompletedStep.add(3)
            newCompletedStep.add(4)
            return newCompletedStep
        })
        setActiveStep(5)
    }

    const handleBack = () => {
        setActiveStep(prevState => prevState - 1)
    }

    const canJumpOptionalStep = (astep) => {
        if (isStepCompleted(astep - 1)) return true;
        if (astep === (activeStep) && isStepOptional(astep)) return true;
        if (isStepOptional(astep - 1))
            return canJumpOptionalStep(astep - 1)
        return false
    }

    const handleStepJump = (astep) => {
        if ((activeStep === steps.length) || (activeStep === astep)) return;
        if (astep < activeStep)
            return setActiveStep(astep)
        if (isStepCompleted(astep))
            return setActiveStep(astep)
        if (canJumpOptionalStep(astep))
            return setActiveStep(astep)

        return notify('w', 'Please complete (all) previous steps before proceeding to this step.')
    }

    const handleStepError = (astep) => {
        setFailedSteps(prevState => {
            const newFailedStep = new Set(prevState.values())
            newFailedStep.add(astep)
            return newFailedStep
        })
    }

    return (
        <Container maxWidth='md'>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant='h5'>{sportId} Registration</Typography>
                <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
                    {
                        steps.map((step, index) => {
                            const stepProps = {}
                            const lableProps = {}

                            if (isStepOptional(index)) {
                                lableProps.optional = (
                                    <Typography variant="body2">Optional</Typography>
                                );
                            }

                            if (isStepFailed(index)) {
                                lableProps.error = true;
                            }

                            if (isStepSkipped(index)) {
                                stepProps.completed = false
                            }

                            return (
                                <Step key={index} completed={isStepCompleted(index)} {...stepProps}>
                                    {
                                        (activeStep === steps.length) ?
                                            <StepLabel {...lableProps}>{step.name}</StepLabel>
                                            :
                                            <StepButton color="inherit" onClick={() => handleStepJump(index)}>
                                                <StepLabel {...lableProps}>
                                                    {step.name}
                                                    {
                                                        lableProps.optional ? <> {lableProps.optional}</> : ''
                                                    }
                                                </StepLabel>
                                            </StepButton>
                                    }
                                    <StepContent>
                                        {
                                            (index < steps.length) ?
                                                <EachStep
                                                    activeStep={index}
                                                    setActiveStep={setActiveStep}
                                                    registrationData={registrationData}
                                                    setRegistrationData={setRegistrationData}
                                                    skippedSteps={skippedSteps}
                                                    setSkippedSteps={setSkippedSteps}
                                                    stepData={steps[index]}
                                                    steplength={steps.length}
                                                    isStepOptional={isStepOptional}
                                                    isStepSkipped={isStepSkipped}
                                                    handleNext={handleNext}
                                                    handleBack={handleBack}
                                                    handleSkip={handleSkip}
                                                    handleStepError={handleStepError}
                                                    updateDataToDB={updateDataToDB}
                                                    failedSteps={failedSteps}
                                                    resetAllData={resetAllData}
                                                    handleAnotherRegisteation={handleAnotherRegisteation}
                                                />
                                                : ''
                                        }
                                    </StepContent>
                                </Step>
                            )
                        })
                    }
                </Stepper>
                {
                    (activeStep === steps.length) ?
                        <>
                            <Typography>
                                You have registered successfully
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <Button onClick={() => (navigate('/sports'))}>Register For Another Sport</Button>
                                <Button onClick={() => (navigate('/viewregisteration'))}>View Your Registeration</Button>
                            </Typography>
                        </>
                        : ''
                }
            </Paper>
        </Container>
    )
}

import React, { useEffect, useState } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { donwDataState } from '../../../Recoil/Sport/atom'
import useFormDownload from './useFormDownload'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
import Loading from '../../../Utils/CustomComponents/Loading/Loading'
import { getLenOfObj } from '../../../Utils/General/objectManipulation'
import useTACDownload from './useTACDownload'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function FormDownload() {
    const downloadData = useRecoilValue(donwDataState)
    const resetData = useResetRecoilState(donwDataState)
    const [loading, setLoading] = useState(false)

    const genDD = useFormDownload()
    const genTACDD = useTACDownload()

    useEffect(() => {

        const genPDF = async () => {
            if (!loading) return;
            const { personalData, tacfor } = downloadData
            const dd = tacfor ? await genTACDD(tacfor) : await genDD(downloadData)
            console.log({ tacfor, dd });

            // const { data } = sportsData[0]
            const pdfDocGenerator = pdfMake.createPdf(dd)
            const filename = tacfor ? `${tacfor} TAC - Vysys Olympiks 2023` : ((personalData?.name ? (personalData.name + '- ') : '') + 'Vysya Olympiks 2023 Registration Form')
            pdfDocGenerator.download(filename + '.pdf')
            setLoading(false)
            return resetData()
        }

        genPDF()

    }, [loading])

    useEffect(() => {
        const genPDF = async () => {
            console.log('inside useeffect');
            if (getLenOfObj(downloadData)) {
                return setLoading(true)
            }
            return setLoading(false)
        }

        genPDF()

        return () => {
            setLoading(false)
            // resetData()
        }

    }, [downloadData])


    if (loading) return <Loading loading={true} />

    return ''
}

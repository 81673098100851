import React from 'react'
import { calculateAgeGroup, convertTimestamp } from '../../Sports/ageGroupCalc';
import { useRecoilValue } from 'recoil';
import { allSportsDataState, generalTACState } from '../../../Recoil/Sport/atom';

import bgImg from './Letter-BG.jpg'
import { objReduce } from '../../../Utils/General/objectManipulation';

const getBase64ImageFromURL = async (url = '') => {
    return await new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
        };

        img.onerror = error => {
            reject(error);
        };

        img.src = url;
    });
}


export default function useFormDownload() {
    const allSportsData = useRecoilValue(allSportsDataState)
    const tacData = useRecoilValue(generalTACState)



    const styles = {
        root: {
            margin: [95, 145, 0, 0],
        },
    }

    const signDD = {
        text: 'Participent Sign',
        bold: true,
        alignment: 'center',
        absolutePosition: { x: 100, y: 730 }
    }

    const writeTAC = (tac = tacData, title = 'Terms and Conditions : ') => {
        return [
            { text: `${title}`, fontSize: 20, bold: true },
            {
                ol: [
                    ...tac.map((eachtac) => {
                        return {
                            text: [
                                { text: eachtac.title + ': ', bold: true },
                                { text: eachtac.content },
                                { text: '\n' },
                                { text: '\n' },
                            ]
                        }
                    })
                ],
                margin: [10, 0, 0, 0]
            },
        ]
    }

    const genDDForTAC = () => {
        return {
            stack: writeTAC(),
            pageBreak: 'before',
            style: 'root'
        }
    }

    const genDDForSport = (sportsData = [], personalData) => {
        const registeredSports = sportsData.map(sport => sport.sportid)

        const dd = registeredSports.map(sportid => {
            const sportData = allSportsData.find(sport => sport.title === sportid)
            const getEvaluation = () => {
                if (sportData.evaluation) return writeTAC(sportData.evaluation.list, sportData.evaluation.title)
                return []
            }
            const getAgeByShowList = () => {
                if (!sportData.showList) return []
                const listName = sportData.showList
                const { dob, gender } = personalData
                const ageGroupData = calculateAgeGroup(sportData, dob, gender)
                return [
                    { text: `${listName} List :\n`, fontSize: 20, bold: true },
                    {
                        ol: [
                            ...ageGroupData[listName].map((eachli) => {
                                return {
                                    text: [
                                        { text: eachli },
                                    ]
                                }
                            })
                        ],
                        margin: [10, 0, 0, 0]
                    },
                ]
            }
            return {
                stack: [
                    { text: `${sportid}`, fontSize: 20, bold: true, alignment: 'center', },
                    ...getEvaluation(),
                    '\n',
                    ...getAgeByShowList(),
                    '\n',
                    ...writeTAC(sportData.tac, 'Sport-Specific Terms and Conditions :'),
                    // signDD,
                ],
                pageBreak: 'before',
                style: 'root'
            }
        })
        return dd
    }

    const getDD = async ({ censusData, personalData, sports }) => {
        const sData = objReduce(sports, (acc, sportid) => ([...acc, { sportid, ...sports[sportid] }]), [])
        const sortTimestamp = (a, b) => (convertTimestamp(a) < convertTimestamp(b)) ? -1 : 1
        const sportsData = [...sData].sort((a, b) => sortTimestamp(a.ts, b.ts))

        const from = {
            columns: [
                {
                    stack: [
                        { text: 'From,', bold: true, },
                        {
                            stack: [
                                personalData.name,
                                { text: 'Census ID : ' + censusData.censusid, bold: true, },
                                { text: 'S/O / C/O, ' + personalData.parentname + ',' },
                                { text: personalData.address + ',' },
                                { text: personalData.town + ',' },
                                { text: personalData.district + ' - ' + personalData.pincode },
                            ],
                            margin: [10, 0, 0, 0]
                        }
                    ]
                },
                {
                    // text: 'Profile',
                    image: await getBase64ImageFromURL(personalData.photourl),
                    width: 100,
                    height: 144,
                    absolutePosition: { x: 480, y: 160 },
                    // alignment: 'right',
                }
            ]
        }

        const to = {
            stack: [
                { text: 'To,', bold: true, },
                {
                    stack: [
                        { text: 'Mahasaba / Youthwing President,' },
                        { text: personalData.town + ',' },
                        { text: personalData.district },
                    ],
                    margin: [10, 0, 0, 0]
                }
            ]
        }

        const subject = {
            text: 'Subject: Request for Participation Approval',
        }

        const paragraphs = {
            stack: [
                {
                    text: 'Esteemed Mahasaba / Youthwing President,', bold: true
                },
                {
                    text: 'I am writing to formally request your approval to participate in the upcoming Vysya Olympiks 2023. As a proud member of our vibrant Vysya community, I am eager to participate in this prestigious event, which serves as a testament to the unity, sportsmanship, and camaraderie of our community.\n\n',
                    alignment: 'justify',
                },
                {
                    text: '\tI kindly request that you verify my Vysya identity and confirm my association with our town. Your approval will greatly contribute to my participation and ensure that I represent our town with honor and pride.\n\n',
                    //Please find attached the necessary documentation to support my request, including proof of my AADHAR.
                    alignment: 'justify',
                },
                {
                    text: 'I intend to participate in the following sports during the event:\n',
                    alignment: 'justify',
                },
                {
                    ol: [...sportsData.map(sport => sport.sportid)],
                    margin: [15, 0], bold: true
                },
                {
                    text: 'Thank you for considering my request',
                    alignment: 'center'
                    //, and I eagerly await your positive response. Your support will not only make a difference in my journey but also strengthen our bond as a community.
                }
            ]
        }

        const sign = {
            stack: [
                {
                    columns: [
                        {
                            stack: [personalData.name, ' Sign']
                        },
                        {
                            stack: [personalData.parentname, ' Sign'],
                        },
                        {
                            stack: [
                                'Mahasaba / Youthwing\n',
                                'President\n',
                                'Seal and Sign'
                            ],
                            // alignment: 'center'
                        },
                    ],
                    bold: true,
                },
                {
                    text: "\nAfter completing signature, please send this form to below WhatsApp numbers", italics: true,
                }
            ],
            // bold: true,
            alignment: 'center',
            absolutePosition: { x: 145, y: 690 },
            margin: [10, 0, 0, 0]
        }

        const qr = {
            stack: [
                { qr: Number(censusData.aadhar).toString(36), },
                { text: Number(censusData.aadhar).toString(36), margin: [20, 0, 0, 0] }
            ],
            absolutePosition: { x: 10, y: 650 }
        }

        const content = [
            {
                stack: [
                    from,
                    '\n',
                    to,
                    '\n',
                    subject,
                    '\n',
                    paragraphs,
                    sign,
                    qr,
                ],
                style: 'root'
            },
            genDDForSport(sportsData, personalData),
            genDDForTAC(),
        ]

        let dd = {
            background: {
                image: await getBase64ImageFromURL(bgImg),
                width: 595,
                height: 842,
            },
            watermark: { text: 'VYSYA OLYMPIKS 2023', opacity: 0.2, bold: true, fontSize: 50 },
            content,
            styles,
            footer: { text: 'Application Code : ' + Number(censusData.aadhar).toString(36), opacity: 0.5, alignment: 'right', margin: [0, 22, 20, 0] }
        }

        return dd

    }


    return getDD
}

import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import React from 'react'

import tnavmsywLogo from '../../Images/logos/tnavmsyw-logo-tbg.png';
// import tnavmsywLogo from '../../Images/logos/youthwing-logo-tbg.png';
import tnavmsLogo from '../../Images/logos/tnavms-logo-tbg.png';
import efactLogo from '../../Images/logos/edu-fed-logo-tbg.png';
import aystLogo from '../../Images/logos/tnavms-logo-tbg.png';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { Parallax } from 'react-scroll-parallax';
import EmojiEventsSharpIcon from '@mui/icons-material/EmojiEventsSharp';
import Diversity2SharpIcon from '@mui/icons-material/Diversity2Sharp';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';


const logoStyle = {
    width: '100%',
    height: '75%',
    objectFit: 'contain', // Maintain aspect ratio and fit within the specified dimensions
};



export default function AboutPage() {

    return (
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                <Container maxWidth="md">
                    <Typography
                        variant="h4"
                        gutterBottom
                        // onClick={scrollToAboutSection}
                        style={{ cursor: 'pointer', color: '#007bff', textAlign: 'center' }}
                    >
                        About Vysya Olympiks 2023
                    </Typography>
                    <Typography variant="h5" paragraph sx={{ textAlign: 'center', marginTop: '5%' }}>
                        Vysya Olympiks is a prestigious sports event that has seen immense success in its past editions. This year, we are excited to continue the tradition with an even grander event.
                    </Typography>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                        In its 4th glorious edition, the Vysya Olympiks is a testament to the unwavering dedication and tireless efforts of the Tamilnadu Arya Vysya Mahasabha Youth Wing. What began as a dream has now become a grand tradition, thanks to the passionate commitment of our youth.
                    </Typography>
                    <Typography variant="h6" style={{ textAlign: 'center', marginTop: '16px' }}>
                        With each passing year, we've overcome challenges, scaled new heights, and united our community through the spirit of sportsmanship. As we embark on this remarkable journey once again, we invite you to witness the indomitable spirit of TNAVMSYW, where tradition meets innovation.
                    </Typography>
                    <Typography variant="h6" style={{ textAlign: 'center', marginTop: '24px' }}>
                        Join us in celebrating not just a sporting event but a legacy built by the youth, for the community. Together, we stride forward, united in pride and purpose.
                    </Typography>

                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            background: '-webkit-linear-gradient(45deg, #09009f,#00ff95  80%)',
                            // background: 'rgb(63, 94, 251)',
                            // background: 'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            background: '#C9FFBF',  /* fallback for old browsers */
                            background: '-webkit-linear-gradient(to left,  #C9FFBF,#FFAFBD)',  /* Chrome 10-25, Safari 5.1-6 */
                            background: 'linear-gradient(to left,  #C9FFBF,#FFAFBD)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                            fontWeight: 'bolder',
                            textAlign: 'center',
                            margin: '10px auto'
                        }}
                    >
                        Join us on December 30th and 31st, 2023, for the 4th iteration of Vysya Olympiks at Tiruvannamalai —an exhilarating celebration of sports and community.
                    </Typography>
                    <Box sx={{ margin: '20px auto', display: 'flex', justifyContent: 'center' }}>
                        <AddToCalendarButton
                            name="VYSYA OLYMPIKS"
                            description="Don't miss out on the fun!"
                            startDate="2023-12-30"
                            startTime="10:00"
                            endDate="2023-12-31"
                            endTime="20:00"
                            timeZone="Asia/Calcutta"
                            location="TIRUVANNAMALAI"
                            organizer="TAMIL NADU ARYA VYSYA MAHA SABHA YOUTH WING|ywolympiks2023@gmail.com"
                            availability="free"
                            options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo', 'Microsoft365', 'MicrosoftTeams']}
                            listStyle="dropdown-static"
                            buttonStyle="date"
                            trigger="click"
                            hideIconButton
                            buttonsList
                            size="5"
                            label="VYSYA OLYMPIKS"
                            lightMode="dark"
                        ></AddToCalendarButton>
                    </Box>
                </Container>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={4}>
                        <Parallax speed={15} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box textAlign="center" >
                                <EmojiEventsSharpIcon sx={{ fontSize: '4rem' }} />
                            </Box>
                            <Box textAlign="center" sx={{ padding: '5px' }}>
                                <Typography variant="h5">9+</Typography>
                                <Typography variant="body1">Sports Events</Typography>
                            </Box>
                        </Parallax>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Parallax speed={10} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box textAlign="center" >
                                <Diversity2SharpIcon sx={{ fontSize: '4rem', }} />
                            </Box>
                            <Box textAlign="center" sx={{ padding: '5px' }}>
                                <Typography variant="h5">3000+</Typography>
                                <Typography variant="body1"> Participants</Typography>
                            </Box>
                        </Parallax>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Parallax speed={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box textAlign="center" >
                                <LanguageSharpIcon sx={{ fontSize: '4rem' }} />
                            </Box>
                            <Box textAlign="center" sx={{ padding: '5px' }}>
                                <Typography variant="h5">World Record</Typography>
                                <Typography variant="body1">Attempt</Typography>
                            </Box>
                        </Parallax>
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                            <Parallax speed={5}>
                                <Box textAlign="center">
                                    <Typography variant="h5">2 Days</Typography>
                                    <Typography variant="body1">Event Duration</Typography>
                                </Box>
                            </Parallax>
                        </Grid> */}
                </Grid>
            </Paper>
        </Container>
    )
}

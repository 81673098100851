
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { Avatar, CssBaseline, FormControlLabel, Slide, Switch, Tab, Tabs, useMediaQuery, useScrollTrigger } from '@mui/material';
import SportsSoccerSharpIcon from '@mui/icons-material/SportsSoccerSharp';
import useAuth from '../Auth/useAuth';

import smallLogo from '../../Images/logos/mini-logo-192x192.png'
import styled from '@emotion/styled';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { themeState } from '../../Recoil/Auth/atom';
import { useTheme } from '@emotion/react';

const pages = ['home', 'sports', 'account', 'sponsor'];
const loginSettings = ['Account', 'Logout', 'Sponsor']
const settings = ['Login', 'Sponsor'];

const HideOnScroll = ({ children }) => {

    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const MaterialUISwitch = styled(Switch)(({ theme }) => {

    const setTheme = useSetRecoilState(themeState)

    return ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                        '#fff',
                    )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
            width: 32,
            height: 32,
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            borderRadius: 20 / 2,
        },
    })
}
);

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function ResponsiveAppBar({ props }) {
    // const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const { isLoggedIn, logout, authUser } = useAuth()

    const navigate = useNavigate()

    const [theme, setTheme] = useRecoilState(themeState)

    const muitheme = useTheme()
    const isMobile = useMediaQuery(muitheme.breakpoints.down('sm'));

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    // const handleCloseNavMenu = (page) => {
    //     navigate('/' + page)
    //     setAnchorElNav(null);
    // };

    const handleCloseUserMenu = (setting = '') => {
        setAnchorElUser(null);
        switch (setting) {
            case 'Logout': {
                logout();
                break;
            }
            case 'Login': {
                if (!isLoggedIn)
                    navigate('/login')
                break;
            }
            case 'Account': {
                navigate('/account')
            }
            case 'Sponsor': {
                navigate('/sponsor')
            }
        }
        return;
    };

    return (
        <React.Fragment>
            <HideOnScroll >
                <AppBar
                    sx={{
                        // background: '#fff',
                        // borderBottom: '1px solid #f0f0f000'
                        // background: '#556270',  /* fallback for old browsers */
                        // background: '-webkit-linear-gradient(to left, #FF6B6B, #556270)',  /* Chrome 10-25, Safari 5.1-6 */
                        // background: 'linear-gradient(to left, #FF6B6B, #556270)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                    }}
                >
                    <Container maxWidth="xl">
                        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                            <Avatar
                                // alt={authUser.displayName}
                                src={smallLogo}
                            />
                            {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar
                                    // alt={authUser.displayName}
                                    src={smallLogo}
                                />
                            </IconButton> */}
                            {/* <SportsSoccerSharpIcon color='primary' fontSize='large' /> */}
                            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />

                            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}

                            {
                                !isMobile ?
                                    // <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    //     <Tabs onChange={(_, index) => navigate('/' + pages[index])} aria-label="basic tabs example">
                                    //         {
                                    //             pages.map((page, index) => {
                                    //                 return <Tab key={page} label={page} {...a11yProps(index)} />
                                    //             })
                                    //         }
                                    //         {/* <Tab label="Item One" {...a11yProps(0)} />
                                    //             <Tab label="Item Two" {...a11yProps(1)} />
                                    //             <Tab label="Item Three" {...a11yProps(2)} /> */}
                                    //     </Tabs>
                                    // </Box>
                                    <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                                        {pages.map((page) => (
                                            <Button
                                                key={page}
                                                onClick={() => navigate('/' + page)}
                                                sx={{ my: 2, display: 'block', color: 'white' }}
                                            >
                                                {page}
                                            </Button>
                                        ))}
                                    </Box>
                                    : ''
                            }

                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Account">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        {
                                            isLoggedIn ?
                                                <Avatar
                                                    alt={authUser.displayName}
                                                    src={authUser.photoURL}
                                                />
                                                :
                                                <PersonIcon />
                                        }

                                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {(isLoggedIn ? loginSettings : settings).map((setting) => (
                                        <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                    <MenuItem>
                                        <FormControlLabel
                                            control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
                                            label={theme ? "Dark" : 'Light'}
                                            onChange={() => setTheme(prevState => !prevState)}
                                        />
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </HideOnScroll>
            <div style={{ height: (isMobile ? '56px' : '68px') }} />
        </React.Fragment>
    );
}
export default ResponsiveAppBar;


// import { AppBar, Box } from '@mui/material'
// import React from 'react'
// import logo from '../../Images/logo.jpeg'
// import { useNavigate } from 'react-router-dom'

// export default function AppNav() {
//     const navigate = useNavigate()

//     return (
//         // <Box
//         //     sx={{ flexGrow: 1 }}
//         // >
//         <AppBar
//             sx={{
//                 position: 'static',
//                 height: '60px',
//                 background: 'rgb(25, 25, 25)',
//                 padding: '10px',
//                 paddingLeft: '10%',
//                 zIndex: '10'
//             }}
//         >
//             <Box
//                 component={'img'}
//                 sx={{ height: '100%', width: '110px' }}
//                 src={logo}
//                 alt='VYSYA-OLIMPIK-LOGO'
//                 onClick={() => navigate('/home')}
//             />
//         </AppBar>

//         // </Box>
//     )
// }

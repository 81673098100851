import { Alert, Box, Button, Container, FormGroup, Link, Snackbar } from '@mui/material'
import React, { useMemo, useState } from 'react'
import notify from '../../Utils/CustomComponents/Notify/notify'
import { registrationDefaultData, registrationFields, registrationValidationSchema, sportFromData } from './defaultData'
import { useFormik } from 'formik'
import CreateFields from './CreateFields'
import * as yup from 'yup'
import Loading from '../../Utils/CustomComponents/Loading/Loading'
import { fdb, fstorage } from '../../Utils/Firebase/firebase'
import { addDoc, doc, setDoc, serverTimestamp, collection, deleteDoc, getDoc, query, where, getDocs, Firestore } from 'firebase/firestore'
import useAuth from '../Auth/useAuth'
import { useParams } from 'react-router-dom'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useRecoilValue } from 'recoil'
import { isForTesting } from '../../Recoil/Auth/selector'

export const convertTimestamp = (timestamp) => {
    if (timestamp) {
        if ((timestamp?.seconds) || (timestamp?._seconds))
            return new Date((timestamp.seconds ?? timestamp._seconds) * 1000)
        if (!isNaN(new Date(Number(timestamp))))
            return new Date(Number(timestamp))
        if (!isNaN(new Date(timestamp)))
            return new Date(timestamp)
    }
    return new Date()
}

export const getFormatedDate = (format, ts) => {
    const timestamp = convertTimestamp(ts)
    const date = timestamp.getDate()
    const month = (timestamp.getMonth() + 1)
    const year = timestamp.getFullYear()
    var hours24 = timestamp.getHours();
    var minutes = timestamp.getMinutes();
    var ampm = hours24 >= 12 ? 'PM' : 'AM';
    var hours = hours24 % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const seconds = timestamp.getSeconds()
    hours24 = hours24 < 10 ? '0' + hours24 : hours24

    if (format === 'yyyy-mm-dd')
        return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`
    if (format === 'yyyy-mm-ddT00:00')
        return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}T${hours24}:${minutes}`
    if (format === 'yyyy-mm-dd hh-mm-ss')
        return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date} ${hours}:${minutes}:${seconds}${ampm}`
    if (format === 'hh-mm-ss')
        return `${hours}:${minutes}:${seconds}${ampm}`
    if (format === 'dd-mm-yyyy')
        return `${date}-${month < 10 ? `0${month}` : month}-${year}`
    if (format === 'dd-mm-yyyy hh-mm')
        return `${date}-${month < 10 ? `0${month}` : month}-${year} ${hours}:${minutes}${ampm}`
    // if (format === 'MMM dd')
    //     return `${shortMonthNames[month - 1]} ${date}`
    if (format === 'hh-mm')
        return `${hours}:${minutes}${ampm}`
    if (format === '24hh-mm')
        return `${hours24}:${minutes}`
    return `${date < 10 ? `0${date}` : date}-${month < 10 ? `0${month}` : month}-${year} ${hours}:${minutes}:${seconds}${ampm}`
}


export default function EachStep({
    activeStep,
    setActiveStep,
    stepData,
    steplength,
    handleNext,
    handleBack,
    handleSkip,
    handleStepError,

    failedSteps = new Set(),
    registrationData,
    setRegistrationData,
    updateDataToDB,
    resetAllData,
    handleAnotherRegisteation,
}) {

    const { sportId } = useParams()
    const activeFormFields = useMemo(() => registrationFields(sportId)?.[activeStep] ?? [], [activeStep, sportId])
    const activeFormDefaultData = useMemo(() => ({ ...(registrationDefaultData(sportId)?.[activeStep] ?? {}), ...(registrationData[activeStep] ?? {}) }), [activeStep, registrationData])
    const activeValidationSchema = useMemo(() => registrationValidationSchema(sportId)?.[activeStep], [activeStep])

    const [valError, setValError] = useState({ open: false, message: '' })

    const { uid } = useAuth()

    const { isMaster } = useRecoilValue(isForTesting)

    const register = async (values) => {
        const data = { ...registrationData, [activeStep]: { ...values } }
        const censusid = data[0].censusid
        const aadhar = (data[0].aadhar) + ''

        const registerationDocRef = await addDoc(
            collection(fdb, sportId),
            {
                uid,
                cts: Number(new Date()).toString(36),
                gents: serverTimestamp(),
                ts: new Date(),
                data,
                censusid,
                aadhar,
                status: 0,
                fts: serverTimestamp()
            }
        )

        await setDoc(
            doc(fdb, 'users', uid),
            {
                registered: {
                    [aadhar]: {
                        [registerationDocRef.id]: sportId
                    }
                }
            },
            { merge: true }
        )

        if (sportId === 'worldrecord')
            await setDoc(
                doc(fdb, 'participents', aadhar),
                {
                    personalData: { ...data[1] },
                    censusData: { ...data[0] },
                    emergencyData: { ...data[2] },
                    worldRecord: { ...data[3] },
                    accommodationData: { ...data[4] },
                    updatedon: Number(new Date()).toString(36),
                    status: 'PENDING'
                },
                { merge: true }
            )
        else
            await setDoc(
                doc(fdb, 'participents', aadhar),
                {
                    registered: {
                        [sportId]: registerationDocRef.id
                    },
                    sports: {
                        [sportId]: {
                            ts: Number(new Date()).toString(36),
                            ...data[5],
                            achievement: {
                                ...data[6]
                            }
                        }
                    },
                    personalData: { ...data[1] },
                    censusData: { ...data[0] },
                    emergencyData: { ...data[2] },
                    worldRecord: { ...data[3] },
                    accommodationData: { ...data[4] },
                    updatedon: Number(new Date()).toString(36),
                    status: 'PENDING'
                },
                { merge: true }
            )

        await setDoc(
            doc(fdb, 'aadhar', aadhar),
            {
                registered: {
                    [registerationDocRef.id]: sportId
                }
            },
            { merge: true }
        )

        await deleteDoc(
            doc(fdb, 'users', uid, 'registration', sportId)
        )

        return true;
    }

    const handleStepSubmit = async (_values, { resetForm }) => {
        let values = { ..._values }
        if (!navigator.onLine) return notify('w', 'Internet Connection Required')

        try {

            if (activeStep === 1) {
                const { photo, ...remaingValues } = values
                if (photo) {
                    const isDBUpdated = await updateDataToDB(activeStep, remaingValues)
                    if (!isDBUpdated) throw new Error('DB Update Failed.')

                    // const folders = ['pp0', 'pp1', 'pp2', 'pp3', 'pp4', 'pp5', 'pp6', 'pp7', 'pp8', 'pp9',]
                    // const foldername = folders[Math.floor(Math.random() * folders.length)]
                    const fileFormat = values.photo.type.split('/').pop();
                    // const folderPath = `profilephotos/${foldername}/`
                    const storageRef = ref(fstorage, `usersphotos/${registrationData[0].aadhar}.${fileFormat}`)
                    const uploadTask = uploadBytesResumable(storageRef, photo)

                    let photourl = await new Promise((resolve, reject) => {
                        uploadTask.on(
                            "state_changed",
                            snapshot => {
                                const percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                                // setUPercent(percentage)
                            },
                            (err) => {
                                // notify('e', 'Something went wrong. Unable to change Logo Right Now.')
                                reject({ ...err, oymsg: 'Image Upload Failed. Choose Another Image and Try Again.' });
                            },
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref)
                                    .then(url => {
                                        resolve(url);
                                    })
                                    .catch(err => {
                                        reject(err);
                                    });
                            }
                        );
                    });
                    values = { ...values, photo: '', photourl }
                }
            }

            const isDBUpdated = await updateDataToDB(activeStep, values)
            if (!isDBUpdated) throw new Error('DB Update Failed.')

            //valdate Census ID
            if (activeStep === 0) {
                //First checking that he already registered
                const _query = query(collection(fdb, sportId), where(`data.0.aadhar`, '==', values.aadhar))

                const isAlreadyRegistered = await getDocs(_query)

                if (!!isAlreadyRegistered.size) {
                    notify('e', 'You Have Been Already Registered.')
                    // resetAllData()
                    if (isMaster) {
                        notify('w', 'For Testing Purpose Allowing You To Register Another Entry With Same Census ID. Happy Testing.')
                    }
                    else
                        return;
                }

                const aadhar = values.aadhar + ''
                const isCensusFoundDoc = getDoc(doc(fdb, 'aadhar', (values.aadhar + '')))
                const participentsDoc = getDoc(doc(fdb, 'participents', (values.aadhar + '')))
                let isCensusFound = false

                if (sportId === 'worldrecord') {
                    if ((await participentsDoc).exists())
                        if ((await participentsDoc).data()?.worldRecord?.interested) {
                            notify('w', 'You Have Been Already Registered For World Record')
                            return;
                        }
                }

                if ((await isCensusFoundDoc).exists()) {
                    const cdata = (await isCensusFoundDoc).data()
                    if (cdata.registered) {
                        const { registered, censusData } = cdata
                        if (!window.confirm(`Welcome ${censusData.name} to Vysya Olympiks.\nYour Census ID: ${censusData.membership_id}. \nClick OK To Proceed.`)) return;
                        const regDocIDs = Object.keys(registered)
                        if ((regDocIDs.length >= 3) && (sportId !== 'worldrecord')) {
                            notify('e', 'You Can Only Participate in 3 Sport')
                            if (isMaster)
                                notify('w', 'For Testing Purpose Allowing You To Register Another Entry With Same Census ID. Happy Testing.')
                            else
                                return;
                        }
                        const regDocId = regDocIDs[0]
                        const sportId = cdata.registered[regDocId]
                        const sportData = getDoc(doc(fdb, sportId, regDocId))
                        if ((await sportData).exists()) {
                            const { data } = (await sportData).data()
                            if (data) {
                                setRegistrationData(prevState => {
                                    const olData = {
                                        ...prevState, [activeStep]: { ...values, censusid: censusData.membership_id }, 1: { ...data[1] }, 2: { ...data[2] }, 3: { ...(data[3] ?? data[2][3] ?? {}) }, 4: { ...(data[4] ?? data[4] ?? {}) }
                                    }
                                    // olData.splice(2, 1, { ...data[2] })
                                    // olData.splice(3, 1, { ...data[3] })
                                    return olData
                                })
                                isCensusFound = true
                                const isDBUpdated = await updateDataToDB(activeStep, values, { 0: { censusid: censusData.membership_id }, 1: { ...data[1] }, 2: { ...data[2] }, 3: { ...(data[3] ?? data[2][3] ?? {}) }, 4: { ...(data[4] ?? data[4] ?? {}) } })
                                if (!isDBUpdated) throw new Error('DB Update Failed.')
                                return handleAnotherRegisteation()
                            }
                        }
                    }
                }

                if (!isCensusFound) {
                    const censusResult = await fetch(`https://tnevysya.com/registration_process.php?type=verify_me_ompk&verify_regd=${values.aadhar}`)
                        // const censusResult = await fetch(`https://tnevysya.com/registration_process.php?type=verify_me&txt_verify_mobile_aadhar=${values.aadhar}`)
                        .then((response) => response.json())
                        .catch(err => {
                            throw err
                        })

                    if (
                        ((censusResult.status + '') !== (0 + ''))
                        // ||
                        // ((censusResult.membership_id + '') !== (censusid + ''))
                    ) {
                        return setValError({
                            open: true,
                            message: <span>
                                Census validation failed.<br />
                                Re-Check Census ID Or Aadhar Number.<br />
                                If Not Registered Yet{' '}
                                <Link href="https://tnevysya.com/districts.php" target="_blank" rel="noopener noreferrer">
                                    Click here to register with Census.
                                </Link>
                            </span>
                        })
                    }
                    if (!window.confirm(`Welcome ${censusResult.name} to Vysya Olympiks.\nYour Census ID: ${censusResult.membership_id}. \nClick OK To Proceed.`)) return;
                    setDoc(
                        doc(fdb, 'aadhar', aadhar),
                        {
                            censusData: { ...censusResult },
                            cts: Number(new Date()).toString(36),
                            gents: serverTimestamp(),
                            uid,
                        }
                    )
                    setRegistrationData(prevState => {
                        const { dob, gender_fk, membership_id, name } = censusResult
                        values = { ...values, censusid: membership_id }
                        return { ...prevState, 1: { ...prevState['2'], gender: ((gender_fk + '') === '1') ? 'male' : 'female', dob: getFormatedDate('yyyy-mm-dd', dob), name } }
                    })
                }
            }

            if (activeStep === (steplength - 1)) {
                if (failedSteps.size != 0) {
                    const failedstep = failedSteps.entries().next().value[0]
                    setActiveStep(failedstep)
                    return notify('e', `Complete Step ${failedstep} To Register`)
                }
                const isregistered = await register(values)
                if (!isregistered) throw new Error('Registeration Failed')
                resetAllData(false)
                resetForm()
            }

            setRegistrationData(prevState => ({ ...prevState, [activeStep]: values }))
            return handleNext()
        } catch (error) {
            console.error({ error });
            return notify('e', error.oymsg ? error.oymsg : 'Something Went Wrong. Try Again Later or Contact Support Team')
        }
    }

    const formik = useFormik({
        initialValues: activeFormDefaultData,
        validationSchema: activeValidationSchema ?? yup.object({
            censusid: yup.string().required('Enter Vysya e-Census ID')
        }),
        onSubmit: handleStepSubmit,
    })

    const formikHandleSkip = () => {
        handleSkip()
    }

    const formikHandleBack = () => {
        if (Object.keys(formik.errors).length) {
            handleStepError(activeStep)
        }
        handleBack()
    }

    const formActions = (istop = false) => {
        return <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
                color='inherit'
                disabled={activeStep === 0}
                onClick={formikHandleBack}
                sx={{ mr: 1 }}
            >
                Back
            </Button>
            <Box
                sx={{ flex: '1 1 auto', textAlign: 'right' }}
            >
                {
                    stepData.optional ?
                        <Button
                            color='inherit'
                            onClick={formikHandleSkip}
                            sx={{ mr: 1 }}
                        >
                            Skip
                        </Button>
                        : ''
                }
            </Box>
            <Button
                type='submit'
            >
                {(activeStep === (steplength - 1)) ? (istop ? '' : 'Register') : 'Next'}
            </Button>
        </Box>
    }

    return (
        <form onSubmit={formik.handleSubmit} autoComplete='off'>
            {formActions(true)}
            <Container>
                <FormGroup>
                    <CreateFields
                        fields={activeFormFields}
                        formik={formik}
                        sportId={sportId}
                        registrationData={registrationData}
                    />
                </FormGroup>
            </Container>
            {formActions()}
            <Loading loading={formik.isSubmitting} />
            <Snackbar
                open={valError.open}
                autoHideDuration={6000}
                onClose={() => setValError({ open: false, message: '' })}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setValError({ open: false, message: '' })} severity="error" sx={{ width: '100%' }}>
                    {valError.message}
                </Alert>
            </Snackbar>
        </form>
    )
}

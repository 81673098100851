import { Avatar, Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormHelperText, IconButton, Link, TextField, Typography } from '@mui/material'
import * as yup from 'yup'
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import TACModal from '../Sports/TACModal';
import TAC from '../Sports/TAC';
import { calculateAgeGroup } from '../Sports/ageGroupCalc';
import TShirtModal from './TShirtModal';
import ShopAgeGroupData from '../Sports/ShopAgeGroupData';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const eCensusDefaultData = {
    censusid: '',
    aadhar: '',
}

const eCensusFields = [
    // {
    //     name: 'censusid',
    //     defaultvalue: '',
    //     fprops: {
    //         label: 'e-Census ID',
    //     },
    // },
    {
        name: 'node',
        field: 'nodeFunc',
        nodeFunc: () => (
            <Typography variant="body2" align="center">
                If you haven't registered for Vysya e-census yet,{' '}
                <Link href="http://tnevysya.com/districts.php" target="_blank" rel="noopener noreferrer">click here to register</Link> first.
            </Typography>
        )
    },
    {
        name: 'aadhar',
        defaultvalue: '',
        fprops: {
            label: 'Aadhar No*',
            InputProps: {
                type: 'number',
                max: 999999999999,
            }
        },
    },
]

const eCensusValidationSchema = yup.object({
    // censusid: yup.string(),
    aadhar: yup
        .number('Enter Valid Aadhar Number')
        .positive('Enter Valid Aadhar Number')
        .required('Enter Aadhar Number')
        .max(999999999999, 'Enter Valid Aadhar Nuber')
        .test(
            'is-valid-aadhar',
            'Enter Valid Aadhar Number',
            function (value) {
                if (!value) return false;

                if ((value + '').length !== 12) return false;
                if (isNaN(value)) return false;
                return true;
            }),
})

const personalDefaultData = {
    name: '',
    mobile: '',
    parentname: '',
    parentmobile: '',
    address: '',
    pincode: '',
    dob: '',
    gender: 'male',
    bloodgroup: '',
    edu: '',
    town: '',
    district: '',
    gothram: '',
    file: '',
    photo: '',
    photourl: '',
    tshirtchest: '',
    tshirtheight: '',
}

const personalDataFields = [
    {
        name: 'gothram',
        defaultvalue: '',
        fprops: {
            label: 'Gothram*'
        }
    },
    {
        name: 'name',
        defaultvalue: '',
        fprops: {
            label: 'Name*'
        }
    },
    {
        name: 'mobile',
        defaultvalue: '',
        fprops: {
            label: 'Mobile (Whatsapp)*',
            InputProps: {
                type: 'number',
                placeholder: 'Whatsapp Number'
            }
        }
    },
    {
        name: 'dob',
        defaultvalue: '',
        fprops: {
            label: 'DOB*',
            InputProps: {
                type: 'date'
            }
        }
    },
    {
        name: 'gender',
        field: 'radio',
        fprops: {
            label: 'Gender*',
            options: [
                { value: 'male', label: 'Male' },
                { value: 'female', label: 'Female' },
            ]
        }
    },
    {
        name: 'bloodgroup',
        field: 'select',
        fprops: {
            label: 'Blood Group*',
            options: [
                'A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'
            ]
        }
    },
    {
        name: 'parentname',
        defaultvalue: '',
        fprops: {
            label: 'Parent / Guardian Name*'
        }
    },
    {
        name: 'parentmobile',
        defaultvalue: '',
        fprops: {
            label: 'Parent / Guardian Mobile (Whatsapp)*',
            InputProps: {
                type: 'number',
                placeholder: 'Whatsapp Number'
            }
        }
    },
    {
        name: 'address',
        defaultvalue: '',
        fprops: {
            label: 'Full Address*',
            multiline: true,
            placeholder: 'No, Building Name,\nAddress Line 1,\nAddress Line 2,\n,Town, District - Pincode'
        }
    },
    {
        name: 'pincode',
        fprops: {
            label: 'Pincode*'
        }
    },
    {
        name: 'edu',
        defaultvalue: '',
        fprops: {
            label: 'Education Qualification*',
        }
    },
    {
        name: 'town',
        defaultvalue: '',
        fprops: {
            label: 'Town*'
        }
    },
    {
        name: 'district',
        field: 'select',
        fprops: {
            label: 'District*',
            options: [
                'Ariyalur', 'Chengalpattu', 'Chennai', 'Coimbatore', 'Cuddalore', 'Dharmapuri', 'Dindigul', 'Erode', 'Kallakurichi', 'Kancheepuram', 'Karur',
                'Krishnagiri', 'Madurai', 'Mayiladuthurai', 'Nagapattinam', 'Kanniyakumari', 'Namakkal', 'Perambalur', 'Pudukottai', 'Ramanathapuram', 'Ranipet',
                'Salem', 'Sivagangai', 'Tenkasi', 'Thanjavur', 'Theni', 'Thiruvallur', 'Thiruvarur', 'Thoothukudi', 'Trichirappalli', 'Thirunelveli', 'Tirupathur',
                'Thiruppur', 'Tiruvannamalai', 'Nilgirils', 'Vellore', 'Viluppuram', 'Virudhunagar', 'Karaikal', 'Pondicherry'
            ]
        }
    },
    {
        name: 'node',
        field: 'nodeFunc',
        nodeFunc: ({ values, handleChange, touched, errors, handleBlur, setFieldValue, ...formik }) => {
            const handleImageClick = () => {
                // Trigger file input click when the image is clicked
                document.getElementById('fileInput').click();
            };
            return (
                <>
                    <FormControl error={touched.photo && Boolean(errors.photo)}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                            <Avatar
                                alt="Photo"
                                src={values.photourl || (values.photo ? URL.createObjectURL(values.photo) : '')}
                                sx={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                onClick={handleImageClick}
                                onBlur={() => formik.setFieldTouched('photo', true)}
                            />
                            <input
                                type="file"
                                id="fileInput"
                                onChange={(event) => {
                                    setFieldValue('photo', event.currentTarget.files[0]);
                                    setFieldValue('photourl', null);
                                }}
                                onBlur={() => formik.setFieldTouched('photo', true)}
                                accept="image/png, image/jpeg, image/jpg"
                                style={{ display: 'none' }}
                            />
                            {values.photo && ( // Display clear button only when a file is selected
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setFieldValue('photo', null);
                                        setFieldValue('photourl', null);
                                    }}
                                    aria-label="Clear file"
                                    onBlur={() => formik.setFieldTouched('photo', true)}
                                >
                                    <ClearIcon />
                                </IconButton>
                            )}
                            <Chip
                                label={(values.photo || values.photourl) ? "Choose Another File" : "Choose File*"}
                                onClick={handleImageClick}
                                onBlur={() => formik.setFieldTouched('photo', true)}
                            />
                        </Box>
                        <FormHelperText>{touched.photo && errors.photo}</FormHelperText>
                    </FormControl>
                    <TextField
                        margin='normal'
                        label='T-Shirt Chest (in inches)*'
                        placeholder='inches'
                        type='number'
                        fullWidth
                        name={'tshirtchest'}
                        id={'tshirtchest'}
                        value={values['tshirtchest']}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched['tshirtchest'] && Boolean(errors['tshirtchest'])}
                        helperText={touched['tshirtchest'] && errors['tshirtchest']}
                        autoComplete='off'
                    />
                    <TextField
                        margin='normal'
                        label='T-Shirt Length (in inches)*'
                        placeholder='in inches'
                        type='number'
                        fullWidth
                        name={'tshirtheight'}
                        id={'tshirtheight'}
                        value={values['tshirtheight']}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched['tshirtheight'] && Boolean(errors['tshirtheight'])}
                        helperText={touched['tshirtheight'] && errors['tshirtheight']}
                        autoComplete='off'
                    />
                    <TShirtModal gender={values.gender} />
                </>
            )
        }
    }
]

const personalDataValidationSchema = yup.object({
    gothram: yup.string().required('Select Gothram'),
    name: yup.string().required('Enter Name'),
    mobile: yup.number('Enter Valid Mobile Number (Whatsapp)').positive('Enter Valid Mobile Number').required('Enter Mobile Number')
        .test(
            'mobile-val',
            'Enter Valid Mobile',
            function (value) {
                return ((value + '').length === 10)
            }
        ),
    dob: yup.date()
        .required('Date of birth is required')
        .test('is-valid-age', 'Age must be between 6 and 60 years', function (value) {
            if (!value) return false;

            function calculateAge(birthdate = value) {
                const today = new Date('29 Dec 2023');
                const dob = new Date(birthdate);

                let years = today.getFullYear() - dob.getFullYear();
                const months = today.getMonth() - dob.getMonth();
                const days = today.getDate() - dob.getDate();

                // Adjust for negative months or days
                if (months < 0 || (months === 0 && days < 0)) {
                    years--;
                }

                return years;
            }

            const today = new Date();
            const dob = new Date(value);
            const age = calculateAge()

            // Check if the person's age is between 5 and 18 years
            return ((age >= 3) && (age <= 100))
        }),
    bloodgroup: yup.string().required('Select Blood Group'),
    parentname: yup.string().required('Enter Name'),
    parentmobile: yup.number('Enter Valid Mobile Number').positive('Enter Valid Mobile Number').required('Enter Mobile Number')
        .test(
            'mobile-val-parent',
            'Enter Valid Mobile',
            function (value) {
                return ((value + '').length === 10)
            }
        ),
    address: yup.string().required('Enter Name'),
    pincode: yup.number()
        .required('Enter Valid Pincode')
        .positive('Enter Valid Pincode')
        .test(
            'pincode-validation',
            'Enter Valid Pincode',
            function (value) {
                if (!value) return false;
                if (isNaN(value)) return false;
                if ((value + '').length !== 6) return false;
                return true;
            }
        )
    ,
    edu: yup.string().required('Enter Name'),
    town: yup.string().required('Enter Name'),
    district: yup.string().required('Enter Name'),
    tshirtchest: yup.number().required('Enter T-Shirt Chest Size (In Inches)').positive('Enter Valid Chest Size'),
    tshirtheight: yup.number().required('Enter T-Shirt Length Size (In Inches)').positive('Enter Valid Length'),
    photo: yup
        .mixed()
        .test(
            'skipValidation',
            'Validation skipped because photourl is found',
            function (value) {
                const { parent } = this;
                const photourl = parent.photourl; // Assuming photourl is a field in your form data

                if (photourl) {
                    return true; // Skip validation when photourl is found
                }

                // Continue with the regular file validation when photourl is not found
                if (!value) {
                    return this.createError({
                        path: this.path,
                        message: 'Please upload your photo',
                    });
                }

                const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
                if (!allowedTypes.includes(value.type)) {
                    return this.createError({
                        path: this.path,
                        message: 'Invalid file type. Only image files are allowed.',
                    });
                }

                if (value.size > 5 * 1024 * 1024) {
                    return this.createError({
                        path: this.path,
                        message: 'File size is too large. Maximum size allowed is 5 MB',
                    });
                }

                if (value.size < 1024) {
                    return this.createError({
                        path: this.path,
                        message: 'File size is too small. Minimum size allowed is 1 KB',
                    });
                }

                return true;
            }
        ),
})

const emergencyContactDefaultData = {
    contactname1: '',
    contactmobile1: '',
    contactrelationship1: '',
    contactname2: '',
    contactmobile2: '',
    contactrelationship2: ''
}

const emergencyContactFields = [
    {
        name: 'contactname1',
        fprops: {
            label: 'Person 1: Name*'
        }
    },
    {
        name: 'contactmobile1',
        fprops: {
            label: 'Person 1: Mobile (Whatsapp)*',
            InputProps: {
                type: 'number',
                placeholder: 'Whatsapp Mobile'
            }
        }
    },
    {
        name: 'contactrelationship1',
        fprops: {
            label: 'Person 1: Relationship*'
        }
    },
    {
        name: 'contactname2',
        fprops: {
            label: 'Person 2: Name (optional)'
        }
    },
    {
        name: 'contactmobile2',
        fprops: {
            label: 'Person 2: Mobile (Whatsapp) (optional)',
            InputProps: {
                type: 'number'
            }
        }
    },
    {
        name: 'contactrelationship2',
        fprops: {
            label: 'Person 2: Relationship (optional)'
        }
    },
]

const emergencyContactValidationSchema = yup.object({
    contactname1: yup.string().required('Enter Name'),
    contactmobile1: yup.number('Enter Valid Mobile Number').positive('Enter Valid Mobile Number').required('Enter Mobile Number')
        .test(
            'mobile-val',
            'Enter Valid Mobile',
            function (value) {
                return ((value + '').length === 10)
            }
        ),
    contactrelationship1: yup.string().required('Select Relationship'),
    contactname2: yup.string(),
    contactmobile2: yup.number()
        .test(
            'emg-mobile-validation',
            'Enter Valid Mobile Number',
            function (value) {
                const { parent } = this
                const { contactname2, contactmobile2, contactrelationship2 } = parent
                if (!(!!contactname2 || !!contactrelationship2)) return true;
                if ((value + '').length !== 10) return false;
                return true;
                // if (value === '') return true
            }
        )
        .test(
            'emg-mobile-validation2',
            'Enter Valid Name/Relationship For this Mobile Number',
            function (value) {
                const { parent } = this
                const { contactname2, contactmobile2, contactrelationship2 } = parent
                return (((value + '').length === 10) ? (!!contactname2 && !!contactrelationship2) : true)
            }
        )
    ,
    contactrelationship2: yup.string(),
})

const accommodationDefaultData = {
    need: false,
    needforparent: false,
    noofparent: 1,
    guidence: false,
    adddata: '',
}

const accommodationFields = [
    {
        name: 'node',
        field: 'nodeFunc',
        nodeFunc: ({ values, handleChange, touched, errors, handleBlur }) => {
            return (
                <>
                    {/* <h2>Accommodation Details</h2> */}
                    <Typography variant="body1" color="textSecondary">
                        We TNAVMSYW, prioritize your comfort and convenience. We are pleased to provide complimentary accommodation arrangements and round-trip transportation from the accommodation place to the stadium. This is our commitment to our community, ensuring your stay is pleasant and hassle-free.
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.need}
                                onChange={handleChange}
                                name="need"
                                onBlur={handleBlur}
                                disabled={values.guidence}
                            />
                        }
                        label="I need accommodation during the event."
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.needforparent}
                                onChange={handleChange}
                                name="needforparent"
                                onBlur={handleBlur}
                                disabled={values.guidence}
                            />
                        }
                        label="I also need accommodation for my parent(s)."
                    />
                    <TextField
                        margin='normal'
                        name="noofparent"
                        label="Number of parents for accommodation"
                        variant="outlined"
                        placeholder='Maximum Allowed Members 2'
                        fullWidth
                        value={values.noofparent}
                        onChange={handleChange}
                        error={touched.noofparent && Boolean(errors.noofparent)}
                        helperText={touched.noofparent && errors.noofparent}
                        onBlur={handleBlur}
                        disabled={values.guidence || !values.needforparent}
                        InputProps={{
                            type: 'number',
                            // inputProps: {
                            //     min: 1,
                            //     max: 2
                            // }
                        }}
                        autoComplete='off'
                    />
                    <br />
                    <Divider >OR</Divider>
                    <br />
                    {/* <Typography variant="body2" color="textSecondary">
                        If you prefer to stay independently but require guidance or information about hotels, our dedicated team at TNAVMSYW is readily available to provide entry-level support. It is our duty and commitment to assist you in making informed choices.
                    </Typography> */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.guidence}
                                onChange={handleChange}
                                name="guidence"
                                disabled={values.need}
                            />
                        }
                        label="I Plan By Self?"
                    />

                    {/* <Typography variant="body2" color="textSecondary">
                        Note:
                        <ul>
                            <li>Our team will provide you with essential information about the hotel and entry-level details to assist you in your booking process.</li>
                            <li>Please be aware that you will be responsible for payment and direct coordination with the hotel for all arrangements.</li>
                        </ul>
                    </Typography> */}

                    <TextField
                        margin='normal'
                        name="adddata"
                        label="Any Other Queries"
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        value={values.adddata}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete='off'
                    />
                </>
            )
        }
    },
]

const accommodationValidationSchema = yup.object({
    need: yup.boolean(),
    needforparent: yup.boolean(),
    noofparent: yup.number()
        .when(['needforparent', 'guidence'], {
            is: (needforparent, guidence) => !!needforparent && !guidence,
            then: () => yup.number()
                .required('Number of parents for accommodation is required')
                .integer('Please enter a valid number')
                .min(1, 'Minimum number of parents is 1')
                .max(2, 'Maximum number of parents allowed is 2'),
        }),
    guidence: yup.boolean(),
    adddata: yup.string(),
})

const worldRecordDefaultData = {
    interested: false,
    certificate: false,
}

const worldRecordFields = [
    {
        name: 'interested',
        field: 'checkbox',
        fprops: {
            label: "I'm Willing To Participate"
        }
    },
    {
        name: 'node',
        field: 'nodeFunc',
        nodeFunc: ({ values, handleChange, touched, errors, handleBlur }) => {
            return (
                <>
                    <Typography>
                        We have planned to create a World Record by creating a man made structure of our God Vasavi Matha & Chant Vasavi Gayathri Mantra as a Group to generate more Devotional Vibrartion.
                    </Typography>
                    <FormControl error={touched['certificate'] && Boolean(errors['certificate'])}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values['certificate']}
                                    onChange={handleChange}
                                    name={'certificate'}
                                    onBlur={handleBlur}
                                />
                            }
                            label={'I Need Certificate'}
                            disabled={!values.interested}
                        />
                        <FormHelperText>
                            {touched['certificate'] && errors['certificate']}
                        </FormHelperText>
                    </FormControl>
                    <Typography>Note : Certificate Will Be Payable.</Typography>
                </>
            )
        }
    }
]

const worldRecordSchema = yup.object({})


const sportAchievementsDefaultData = {
    achieved: false,
    achievementdetails: '',
    achievementlevel: '',
}

const sportAchievementsFields = [
    {
        name: 'achieved',
        field: 'checkbox',
        fprops: {
            label: 'Do you have any sports achievements?',
        }
    },
    {
        name: 'achievementlevel',
        fprops: {
            label: "Level of Achievement (e.g., State, District, National)",
            placeholder: 'State / District / National - Player/Winner',
        }
    },
    {
        name: 'achievementdetails',
        fprops: {
            label: "Please describe your sports achievements",
            variant: "outlined",
            multiline: true,
            rows: 4,
            placeholder: 'Describe Your Achievement(s)'
        }
    },
]

const sportAchievementsValidationSchema = yup.object({
    achievementlevel: yup.string()
        .test(
            'achievementlevel-test',
            'Enter Your Achievement Level',
            function (value) {
                const { parent } = this
                return parent.achieved ? !!value : true
            }
        )
})


const sportTACField = {
    name: 'tac',
    field: 'nodeFunc',
    nodeFunc: ({ values, handleChange, touched, errors, handleBlur, setFieldValue, ...formik }, sportId, sport, registrationData) => {
        return <>
            <ShopAgeGroupData sportid={sportId} sport={sport} userBirthdate={registrationData[1].dob} gender={registrationData[1].gender} />
            {/* <Typography gutterBottom>
                Your Age Group : {calculateAgeGroup(sport, registrationData[1].dob, registrationData[1].gender)}
            </Typography> */}
            <FormControl error={touched.tac && Boolean(errors.tac)}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.tac}
                            onChange={handleChange}
                            name={'tac'}
                            onBlur={handleBlur}
                        />
                    }
                    label={<><TACModal openbutton sportid={sportId} onlysport /><span>I Accept The Sport Terms And Conditions.</span></>}
                />
                <FormHelperText>
                    {touched.tac && errors.tac}
                </FormHelperText>
            </FormControl>
        </>
    },
}

const tandcDefaultData = {
    agree: false,
}

const tandcFiedls = [
    {
        name: 'node',
        field: 'nodeFunc',
        nodeFunc: ({ values, handleChange, touched, errors, handleBlur }, sportid) => (
            <>
                <TAC sportid={sportid} />
                <FormControl error={touched.agree && Boolean(errors.agree)}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.agree}
                                onChange={handleChange}
                                name="agree"
                                onBlur={handleBlur}
                            />
                        }
                        label="I accept the terms and conditions*"
                    />
                    <FormHelperText>
                        {touched.agree && errors.agree}
                    </FormHelperText>
                </FormControl>
            </>
        )
    }
]

const tandcValidationSchema = yup.object({
    agree: yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('You must accept the terms and conditions'),
})


const athleticsDefaultData = {
    a100: false,
    a200: false,
    a400: false,
    relay: false,
    teamname: '',
    teamheadmobile: '',
    shotput: false,
    longjump: false,
    tac: false,
}

const athleticsFields = [
    {
        name: 'a100',
        field: 'checkbox',
        fprops: {
            label: '100 Meters'
        }
    },
    {
        name: 'a200',
        field: 'checkbox',
        fprops: {
            label: '200 Meters'
        }
    },
    {
        name: 'a400',
        field: 'checkbox',
        fprops: {
            label: '400 Meters'
        }
    },
    {
        name: 'relay',
        field: 'checkbox',
        fprops: {
            label: '100 X 4 (Relay)'
        }
    },
    {
        name: 'node',
        field: 'nodeFunc',
        nodeFunc: ({ values, handleChange, touched, errors, handleBlur, setFieldValue, ...formik }) => {
            return (
                <>
                    <TextField
                        margin='normal'
                        name="teamname"
                        label="Team Name"
                        variant="outlined"
                        fullWidth
                        value={values.teamname}
                        onChange={handleChange}
                        disabled={!values.relay}
                        onBlur={handleBlur}
                        error={touched['teamname'] && Boolean(errors['teamname'])}
                        helperText={touched['teamname'] && errors['teamname']}
                        autoComplete='off'
                    />
                    <TextField
                        margin='normal'
                        name="teamheadmobile"
                        type='number'
                        label="Team Lead Mobile"
                        variant="outlined"
                        fullWidth
                        value={values.teamheadmobile}
                        onChange={handleChange}
                        disabled={!values.relay}
                        onBlur={handleBlur}
                        error={touched['teamheadmobile'] && Boolean(errors['teamheadmobile'])}
                        helperText={touched['teamheadmobile'] && errors['teamheadmobile']}
                        autoComplete='off'
                    />
                </>
            )
        }
    },
    {
        name: 'shotput',
        field: 'checkbox',
        fprops: {
            label: 'Shotput'
        }
    },
    {
        name: 'longjump',
        field: 'checkbox',
        fprops: {
            label: 'Long Jump'
        }
    },
    sportTACField
]

const athleticsSchema = yup.object({
    a100: yup.boolean()
        .test(
            'any-one-true',
            'You Have To Select Atlease One.',
            function (values) {
                const { parent } = this
                if (parent.a100 || parent.a200 || parent.a400 || parent.relay || parent.shotput || parent.longjump)
                    return true
                return false
            }
        ),
    a200: yup.boolean(),
    a400: yup.boolean(),
    relay: yup.boolean(),
    teamname: yup.string()
        .test(
            'for-relay-team',
            'Enter Team Name',
            function (value) {
                const { parent } = this
                //ox3L4
                if (parent.relay)
                    return (!!value)
                return true
            }
        ),
    teamheadmobile: yup.string()
        .test(
            'for-relay-team-mobile',
            'Enter Team Head Mobile',
            function (value) {
                const { parent } = this
                //ox3L4
                if (parent.relay)
                    return (!!value && ((value + '').length === 10))
                return true
            }
        ),
    shotput: yup.boolean(),
    longjump: yup.boolean(),
    tac: yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('You must accept the terms and conditions'),
})

const athleticsData = {
    sportDefaultData: athleticsDefaultData,
    sportFields: athleticsFields,
    sportSchema: athleticsSchema
}

const shuttlecockDefaultData = {
    // game: 'individual',
    single: false,
    pair: false,
    teamheadmobile: '',
    teamname: '',
    tac: false,
}

const shuttlecockFields = [
    {
        name: 'single',
        field: 'checkbox',
        fprops: {
            label: 'Single',
        }
    },
    {
        name: 'pair',
        field: 'checkbox',
        fprops: {
            label: 'Pair',
        }
    },
    {
        name: 'node',
        field: 'nodeFunc',
        nodeFunc: ({ values, handleChange, touched, errors, handleBlur, setFieldValue, ...formik }, sportId) => {
            if ((values.pair))
                return (
                    <>
                        <TextField
                            margin='normal'
                            name="teamname"
                            label="Team Name*"
                            variant="outlined"
                            fullWidth
                            value={values.teamname}
                            onChange={handleChange}
                            disabled={!values.pair}
                            onBlur={handleBlur}
                            error={touched['teamname'] && Boolean(errors['teamname'])}
                            helperText={touched['teamname'] && errors['teamname']}
                            autoComplete='off'
                        />
                        <TextField
                            margin='normal'
                            name="teamheadmobile"
                            type='number'
                            label="Team Lead Mobile*"
                            variant="outlined"
                            fullWidth
                            value={values.teamheadmobile}
                            onChange={handleChange}
                            disabled={!values.pair}
                            onBlur={handleBlur}
                            error={touched['teamheadmobile'] && Boolean(errors['teamheadmobile'])}
                            helperText={touched['teamheadmobile'] && errors['teamheadmobile']}
                            autoComplete='off'
                        />
                    </>
                )
            return ''
        },
    },
    sportTACField,
]

const shuttlecockSchema = yup.object({
    single: yup.boolean()
        .test(
            'any-one-true',
            'You Have To Select Atlease One.',
            function (values) {
                const { parent } = this
                if (parent.single || parent.pair)
                    return true
                return false
            }
        ),
    teamname: yup.string()
        .test(
            'for-shuttle-team',
            'Enter Team Name',
            function (value) {
                const { parent } = this
                //ox3L4
                if (parent.pair)
                    return (!!value)
                return true
            }
        ),
    teamheadmobile: yup.string()
        .test(
            'for-shuttle-team-mobile',
            'Enter Team Head Mobie',
            function (value) {
                const { parent } = this
                //ox3L4
                if (parent.pair)
                    return (!!value)
                return true
            }
        ),
    tac: yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('You must accept the terms and conditions'),
})

const shuttlecockData = {
    sportDefaultData: shuttlecockDefaultData,
    sportFields: shuttlecockFields,
    sportSchema: shuttlecockSchema,
}

const swimmngDefaultData = {
    // game: 'inline',
    tac: false,
}

const swimmingFields = [
    // {
    //     name: 'game',
    //     field: 'radio',
    //     fprops: {
    //         label: 'Game Type*',
    //         options: [
    //             { value: 'inline', label: 'INLINE' },
    //             { value: 'quad', label: 'QUAD' },
    //         ]
    //     }
    // },
    sportTACField,
]

const swimmingSchema = yup.object({
    // game: yup.string().required('Select Game Type'),
    tac: yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('You must accept the terms and conditions'),
})

const swimmingData = {
    sportDefaultData: swimmngDefaultData,
    sportFields: swimmingFields,
    sportSchema: swimmingSchema,
}

const skatingData = {
    sportDefaultData: { tac: false, game: 'inline' },
    sportFields: [
        {
            name: 'game',
            field: 'radio',
            fprops: {
                label: 'Game Type*',
                options: [
                    { value: 'inline', label: 'INLINE' },
                    { value: 'quad', label: 'QUAD' },
                ]
            }
        },
        sportTACField,
    ],
    sportSchema: yup.object({
        game: yup.string().required('Select Game'),
        tac: yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('You must accept the terms and conditions')
    })
}

const kabaddiData = {
    sportDefaultData: {
        tac: false, game: 'below', teamname: '', teamheadmobile: '',
    },
    sportFields: [
        {
            name: 'game',
            field: 'radio',
            fprops: {
                label: 'Weight Group',
                options: [
                    { value: 'below', label: 'Below 40KG' },
                    { value: 'above', label: 'Above 40KG' }
                ]
            }
        },
        {
            name: 'teamname',
            fprops: {
                label: 'Team Name'
            }
        },
        {
            name: 'teamheadmobile',
            fprops: {
                label: 'Team Head Mobile',
                type: 'number',
            }
        },
        sportTACField,
    ],
    sportSchema: yup.object({
        game: yup.string().required('Select Game'),
        teamname: yup.string().required('Enter Team Name'),
        teamheadmobile: yup.string()
            .test(
                'for-shuttle-team-mobile',
                'Enter Team Head Mobile',
                function (value) {
                    //ox3L4
                    return (!!value && ((value + '').length === 10))
                }
            ),
        tac: yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('You must accept the terms and conditions')
    })
}

const commonSportData = {
    sportDefaultData: { tac: false },
    sportFields: [sportTACField],
    sportSchema: yup.object({ tac: yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('You must accept the terms and conditions'), })
}


export const sportFromData = {
    Athletics: athleticsData,
    Shuttlecock: shuttlecockData,
    Swimming: commonSportData,
    Carrom: commonSportData,
    Yoga: commonSportData,
    Chess: commonSportData,
    Silambam: commonSportData,
    Kabaddi: kabaddiData,
    Skating: skatingData,
}

export const registrationDefaultData = (sportid) => [
    eCensusDefaultData,
    personalDefaultData,
    emergencyContactDefaultData,
    worldRecordDefaultData,
    accommodationDefaultData,
    sportFromData?.[sportid]?.sportDefaultData ?? {},
    sportAchievementsDefaultData,
    tandcDefaultData,
]

export const registrationFields = (sportid) => [
    eCensusFields,
    personalDataFields,
    emergencyContactFields,
    worldRecordFields,
    accommodationFields,
    sportFromData?.[sportid]?.sportFields ?? [],
    sportAchievementsFields,
    tandcFiedls,
]

export const registrationValidationSchema = (sportid) => [
    eCensusValidationSchema,
    personalDataValidationSchema,
    emergencyContactValidationSchema,
    worldRecordSchema,
    accommodationValidationSchema,
    sportFromData?.[sportid]?.sportSchema ?? yup.object({}),
    sportAchievementsValidationSchema,
    tandcValidationSchema,
]

export const getDefaultDataFromFields = (fields = []) => {
    return fields.reduce((acc, field) => ({ ...acc, [field.name]: (field?.defaultvalue ?? '') }), {})
}
import React from 'react'
import { Container } from '@mui/material'

const UploadNew = () => {
    return ''
}

export default function Gallery() {

    return (
        <Container
            maxWidth="lg"
            sx={{
                padding: '24px',
            }}
        >

            <UploadNew />
        </Container>
    )
}

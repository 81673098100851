import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Typography } from '@mui/material';
import Loading from '../../Utils/CustomComponents/Loading/Loading';
import RegistrationForm from './RegistrationForm';
import RegistrationForm2 from './RegistrationForm2';
import { doc, getDoc } from 'firebase/firestore';
import { fdb } from '../../Utils/Firebase/firebase';
import useAuth from '../Auth/useAuth';
import { useRecoilValue } from 'recoil';
import { allSportsDataState } from '../../Recoil/Sport/atom';
import notify from '../../Utils/CustomComponents/Notify/notify';

const default_sportsData = [
    {
        title: 'Athletics',
        // img: athleticsImg,
        description: 'Join us for exciting track and field events.',
    },
    {
        title: 'Chess',
        // img: chessImg,
        description: 'Test your strategic skills in intense chess matches.',
    },
    {
        title: 'Carrom',
        // img: carromImg,
        description: 'Compete in a game of precision and tactics with carrom.',
    },
    {
        title: 'Shuttle',
        // img: shuttleImg,
        description: 'Experience fast-paced badminton matches in the shuttle event.',
    },
    {
        title: 'Swimming',
        // img: swimmingImg,
        description: 'Make a splash and race in the swimming competition.',
    },
    {
        title: 'Yoga',
        // img: yogoImg,
        description: 'Find your inner peace and flexibility in the yoga event.',
    },
    {
        title: 'Silambam',
        // img: silambamImg,
        description: 'Witness the artistry of silambam in this unique event.',
    },
    {
        title: 'Skating',
        // img: skatingImg,
        description: 'Rollerblade your way to victory in the skating competition.',
    },
    {
        title: 'Kabaddi',
        // img: kabadiImg,
        description: 'Get ready for intense team action in the kabaddi tournament.',
    },
];

export default function Regiestration() {
    const [loading, setLoading] = useState(true)
    const [oldData, setOldData] = useState({})

    const { sportId } = useParams()
    const { uid } = useAuth()
    const navigate = useNavigate()

    const sportsData = useRecoilValue(allSportsDataState)

    useEffect(() => {
        const getSportData = async () => {
            if (!sportId) return navigate('/sports')
            if (!navigator.onLine) {
                notify('w', "You're Offline")
                return navigate('/sports')
            }
            if (sportsData.findIndex(sport => (sport.title === sportId)) < 0) return navigate('/sports')

            const docSanp = await getDoc(doc(fdb, 'users', uid, 'registration', sportId))
            if (docSanp.exists()) {
                setOldData(docSanp.data())
            }
            return setLoading(false)
        }

        getSportData()

    }, [sportId, uid])

    if (loading)
        return <Loading
            loading={loading}
        />

    return (
        <>
            <Container
                maxWidth="lg"
                sx={{
                    // background: '#3E5151',  /* fallback for old browsers */
                    // background: '-webkit-linear-gradient(to right, #DECBA4, #3E5151)',  /* Chrome 10-25, Safari 5.1-6 */
                    // background: 'linear-gradient(to right, #DECBA4, #3E5151)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                    padding: '24px',
                    minHeight: '100vh',
                    width: '100vw',
                }}
                style={{ width: '100vw', minWidth: '100vw' }}
            >
                {/* <Typography variant='v4'>{sportId}</Typography>
                <Typography>{sportsData.find(sport => (sport.title === sportId)).description}</Typography> */}

                {/* <RegistrationForm /> */}
                <RegistrationForm2 oldData={oldData} resetOldData={() => setOldData({})} />
            </Container >
        </>
    )
}


// https://tnevysya.com/registration_process.php?type=verify_me&txt_verify_mobile_aadhar=12_digit_aadhar_number
import React from 'react'
import FullPageBlur from './FullPageBlur/FullPageBlur'
import ShapesLoader from './ShapesLoader/ShapesLoader'
import JumpLoader from './JumpLoader/JumpLoader';
import SquareInCircleLoader from './SquareInCircleLoader/SquareInCircleLoader';
import { Backdrop } from '@mui/material';

export default function Loading({ loading = false, type = '' }) {
    const loaders = [ShapesLoader, JumpLoader, SquareInCircleLoader]

    const getLoaders = () => {
        // if (!loading) return ''
        const LoadingComponent = loaders?.[type] || loaders[Math.floor(Math.random() * loaders.length)]

        switch (type) {
            case 1: {
                const Compo = loaders[1]
                return <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        // background: 'rgba(255, 255, 255, 0.15)',
                    }}
                    open={loading}
                >
                    <Compo />
                </Backdrop>
            }

            case 2: {
                const Compo = loaders[2]
                return <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        // background: 'rgba(255, 255, 255, 0.15)',
                    }}
                    open={loading}
                >
                    <Compo />
                </Backdrop>
            }

            case 0:
            default: {
                const Compo = loaders[0]
                return <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        background: 'rgb(160 160 160 / 50%)',
                    }}
                    open={loading}
                >
                    <Compo />
                </Backdrop>
            }

        }
        return <LoadingComponent />
    }

    return (
        <>
            {getLoaders()}
        </>
    )
}

import { doc, getDoc, setDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { fdb } from '../../Utils/Firebase/firebase'
import useAuth from '../Auth/useAuth'
import { Button, Container, Grid, IconButton, List, ListItem, ListItemText, Paper, Tooltip, Typography, styled, TextField, FormGroup, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import FormDownload from './FormDownload/FormDownload'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { donwDataState } from '../../Recoil/Sport/atom'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import notify from '../../Utils/CustomComponents/Notify/notify'
import { isForTesting } from '../../Recoil/Auth/selector'
import { getLenOfObj, getLenOfObjBool, objKeyToArr } from '../../Utils/General/objectManipulation'
import { useFormik } from 'formik'
import CreateFields from './CreateFields'
import * as yup from 'yup'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
}));


const EditParticipent = ({ id, close, participent }) => {
    // const participent = useRecoilValue(participentDataState(id))

    const submit = async (values) => {
        if (!navigator.onLine) return notify('e', 'Internet connection required')
        const docid = parseInt(id, 36) + ''
        setDoc(
            doc(fdb, 'participents', docid),
            {
                personalData: {
                    ...values
                }
            },
            { merge: true }
        )
        close()
    }

    const formik = useFormik({
        initialValues: { name: participent.personalData.name, dob: participent.personalData.dob },
        validationSchema: yup.object({
            name: yup.string().required('Enter Name'),
            dob: yup.string().required('Enter DOB')
        }),
        onSubmit: submit,
    })

    if (!participent) return <>Participent Not Found</>

    return (
        <Container >
            <Typography align='center'>Application ID : {id}</Typography>
            <form onSubmit={formik.handleSubmit} autoComplete='off'>
                <FormGroup>
                    <CreateFields
                        fields={[
                            { name: 'name', fprops: { label: 'Name' } },
                            {
                                name: 'dob', fprops: { label: 'DOB', InputProps: { type: 'date' } }
                            },
                        ]}
                        formik={formik}
                    />
                    <Stack spacing={2} direction="row">
                        <Button variant='contained' color='success' type='submit' size='small'>Update</Button>
                        <Button variant='contained' color='primary' onClick={() => close()} size='small'>Close</Button>
                    </Stack>
                </FormGroup>
            </form>
        </Container>
    )
}


function ForEachAadhar({ aid = '' }) {

    const [participentsData, setParticipentsData] = useState({})
    const [loading, setLoading] = useState(true)
    const setDownData = useSetRecoilState(donwDataState)

    const { isMaster } = useRecoilValue(isForTesting)

    const navigate = useNavigate()

    useEffect(() => {
        const getData = async () => {
            const adocid = aid + ''
            const docData = getDoc(doc(fdb, 'participents', adocid))
            setParticipentsData((await docData).data())

            // const cenDocData = (await docData).data()

            // setCensusData(cenDocData)
            // const sportidsArr = Object.keys(sportids)
            // const sportDocData = await Promise.all([
            //     ...sportidsArr.map(docid => getDoc(doc(fdb, (sportids[docid] + ''), (docid + ''))))
            // ]).then(result => {
            //     return result.reduce((acc, res, index) => {
            //         if (res.exists())
            //             return [...acc, ({ ...res.data(), sportid: sportids[sportidsArr[index]] })]
            //         return [...acc]
            //     }, [])
            // })
            // console.log({ cenDocData, sportDocData });
            // setSportsData(sportDocData)
            setLoading(false)
        }

        getData()

        return () => {
            // setCensusData({})
            // setSportsData({})
            setParticipentsData({})
            setLoading(true)
        }

    }, [])

    const download = () => {
        // notify('w', 'Form Will Get Ready To Download By Oct 30th. Thank You For Your Patience')
        setDownData(participentsData)
        if (isMaster) {
            return notify('w', 'For Testing Allowing To Print')
        }
        return;
    }

    if (getLenOfObjBool(participentsData))
        return (
            <>
                <Grid item xs={12} sm={4}>
                    <Item>
                        {
                            loading ? 'Loading...' : <>
                                <Typography>
                                    Census ID: {participentsData.censusData.censusid || ''}
                                </Typography>
                                <Typography>
                                    Name : {participentsData.personalData.name}
                                </Typography>
                                <Typography>
                                    DOB : {new Date(participentsData.personalData.dob).toDateString()}
                                </Typography>
                                <br />
                                {
                                    participentsData?.worldRecord?.interested ?
                                        <>
                                            <Typography variant="body1" color="secondary">
                                                Thank for showing interest to participate in World Record.
                                            </Typography>
                                            {
                                                participentsData.certpaid ?
                                                    <Typography variant="body1" color="secondary">
                                                        Payment Received For Your Certificate
                                                    </Typography>
                                                    :
                                                    <>
                                                        <Typography variant="body1" color={'error'}>
                                                            <br />
                                                            {
                                                                participentsData?.worldRecord?.certificate ?
                                                                    'Payment pending for your certificate' :
                                                                    'If you are willing to obtain a certificate'
                                                            }
                                                        </Typography>
                                                        <Button onClick={() => navigate('/wrpay?ai=' + (Number(participentsData.censusData.aadhar).toString(36)))} variant='outlined'>Click here to pay</Button>
                                                        <br />
                                                    </>
                                            }
                                        </>
                                        : ''
                                }
                                <br />
                                <Typography>
                                    Total Sport Registered : {getLenOfObj(participentsData.sports)}
                                </Typography>
                                <List>
                                    <div style={{ marginBottom: '20px' }}>
                                        {objKeyToArr(participentsData.sports).map((sportid, sportIndex) => (
                                            <ListItem key={sportIndex} >
                                                <b>{sportIndex + 1} .</b><span>{"  "}</span><ListItemText primary={sportid} />
                                            </ListItem>
                                        ))}
                                    </div>
                                </List>
                                {/* <Typography>
                                    {
                                        getLenOfObj(participentsData.sports) < 2 ? <span>Your Can Register Upto 3 Events.<span> <Button onClick={() => navigate('/sports')}>Ckick Here</Button></span> To Register More</span> : ''
                                    }
                                </Typography> */}
                                <Typography>
                                    Status : {(participentsData.status === 'PENDING') ? 'ON-SPOT APPROVAL' : participentsData.status}
                                </Typography>
                                <Tooltip title="Download Form">
                                    <IconButton onClick={download}>
                                        <DownloadOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    </Item>
                </Grid>
                {/* {
                loading ? '' :
                    <FormDownload sportsData={sportsData} censusData={censusData.censusData} />
            } */}
            </>
        )

    return ''
}

function CustomDownload() {
    const [regData, setRegData] = useState({ aid: '', aadhar: '' })
    const [sportIds, setSportIds] = useState({})

    const handleChange = ({ target: { value } }) => setRegData({ aadhar: value })

    const fetchData = async (aadhar = '') => {
        try {
            const adocid = aadhar + ''
            const docData = getDoc(doc(fdb, 'aadhar', adocid))
            const aadharDocData = (await docData).data()
            if (!!aadharDocData.registered && (Object.keys(aadharDocData.registered).length > 0))
                return setSportIds(aadharDocData.registered)
            return notify('e', 'No Data Found')
        } catch (error) {
            console.error({ error });
            return notify('e', 'No Data Found')

        }
    }

    const search = () => {
        if (!navigator.onLine) return notify('e', 'Internet Connection Required')
        let aadhar = regData.aadhar
        if (!isNaN(Number(aadhar)) && ((aadhar + '').length === 12))
            return fetchData(aadhar)
        aadhar = parseInt(aadhar, 36)
        if (!isNaN(aadhar) && ((aadhar + '').length === 12)) {
            setRegData(prevState => ({ ...prevState, aid: aadhar }))
            return fetchData(aadhar)
        }
        return notify('e', 'Enter Valid Data')
    }

    const reset = () => {
        setRegData({ aadhar: '', aid: '' })
        setSportIds({})
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Item>
                    <Typography>Search Your Registeration. If You Registered With Someother Account</Typography>
                    <TextField
                        margin='normal'
                        fullWidth
                        name={'aadhar'}
                        // id={name}
                        value={regData.aadhar}
                        onChange={handleChange}
                        autoComplete='off'
                        label='Aadhar Number'
                    />
                    <Button onClick={search} color='success'>Search</Button>
                    <Button onClick={reset} color='error'>Reset</Button>
                </Item>
            </Grid>
            {
                (!!Object.keys(sportIds).length && !!regData.aadhar) ?
                    <ForEachAadhar aid={regData.aid || regData.aadhar} sportids={sportIds} /> : ''
            }

        </Grid>
    )

}

export default function ViewRegisteration() {
    const [registerationData, setRegisterationData] = useState({})
    const [loading, setLoading] = useState(true)

    const { uid } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        const getData = async () => {
            if (navigator.onLine) {
                const docRef = getDoc(doc(fdb, 'users', uid))
                if ((await docRef).exists()) {
                    setLoading(false)
                    return setRegisterationData((await docRef).data())
                }
            }
            setLoading(false)
            setRegisterationData({})
        }

        getData()

        return () => {
            setRegisterationData({})
        }
    }, [])

    return (
        <Container maxWidth='lg'>
            {
                loading ?
                    ''
                    :
                    <>
                        <Typography variant='h4' textAlign={'center'}>Your Registeration</Typography>
                        {
                            (registerationData.registered && Object.keys(registerationData.registered).length) ?
                                <Grid container spacing={2}>
                                    {
                                        Object.keys(registerationData.registered).map((aid, indez) => {
                                            // if (indez !== 0) return ''
                                            return <ForEachAadhar key={aid} aid={aid} sportids={registerationData.registered[aid]} />
                                        })
                                    }
                                </Grid>
                                : <div className="no-registeration-found">
                                    No Registration Yet. <Button onClick={() => (navigate('/sports'))} variant='contained'>Click Here to Register</Button>
                                </div>
                        }
                        <CustomDownload />
                    </>
            }

            <FormDownload />
        </Container>
    )
}

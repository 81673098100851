import React, { useMemo } from 'react'
import { allSportsDataState, generalTACState } from '../../../Recoil/Sport/atom'
import { useRecoilValue } from 'recoil'
import bgImg from './Letter-BG.jpg'

const getBase64ImageFromURL = async (url = '') => {
    return await new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
        };

        img.onerror = error => {
            reject(error);
        };

        img.src = url;
    });
}


const styles = {
    root: {
        margin: [95, 145, 0, 0],
    },
}

export default function useTACDownload() {
    const allSportsData = useRecoilValue(allSportsDataState)
    const termsAndConditions = useRecoilValue(generalTACState)

    const allTSCS = useMemo(() => {

        const sportsTAC = allSportsData.reduce((acc, { title, tac }) => {
            return {
                ...acc,
                [title]: [...tac]
            }
        }, {})

        return { ...sportsTAC, General: termsAndConditions }
    }, [])

    const writeTAC = (tac = termsAndConditions, title = 'Terms and Conditions : ') => {
        return {
            stack: [
                { text: `${title}`, fontSize: 20, bold: true },
                {
                    ol: [
                        ...tac.map((eachtac) => {
                            return {
                                text: [
                                    { text: eachtac.title + ': ', bold: true },
                                    { text: eachtac.content },
                                    { text: '\n' },
                                    { text: '\n' },
                                ]
                            }
                        })
                    ],
                    margin: [10, 0, 0, 0]
                },
            ],
            style: 'root'
        }
    }

    const getTACdd = async (tacfor = 'General') => {

        const tac = allTSCS[tacfor]

        const content = [
            writeTAC(tac, tacfor + ' Terms and Conditions :'),
        ]

        let dd = {
            background: {
                image: await getBase64ImageFromURL(bgImg),
                width: 595,
                height: 842,
            },
            watermark: { text: 'VYSYA OLYMPIKS 2023', opacity: 0.2, bold: true, fontSize: 50 },
            content,
            styles,
            // footer: { text: 'Application Code : ' + Number(censusData.aadhar).toString(36), opacity: 0.5, alignment: 'right', margin: [0, 22, 20, 0] }
        }

        return dd
    }

    return getTACdd
}

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Typography } from '@mui/material';
import Loading from '../../Utils/CustomComponents/Loading/Loading';
import { doc, getDoc } from 'firebase/firestore';
import { fdb } from '../../Utils/Firebase/firebase';
import useAuth from '../Auth/useAuth';
import { useRecoilValue } from 'recoil';
import { allSportsDataState } from '../../Recoil/Sport/atom';
import notify from '../../Utils/CustomComponents/Notify/notify';
import WorldRecordReg from './WorldRecordReg';

export default function Regiestration2() {
    const [loading, setLoading] = useState(true)
    const [oldData, setOldData] = useState({})

    const { sportId } = useParams()
    const { uid } = useAuth()
    const navigate = useNavigate()

    const sportsData = useRecoilValue(allSportsDataState)

    useEffect(() => {
        const getSportData = async () => {
            if (!sportId) return navigate('/sports')
            if (!navigator.onLine) {
                notify('w', "You're Offline")
                return navigate('/sports')
            }
            // if (sportsData.findIndex(sport => (sport.title === sportId)) < 0) return navigate('/sports')

            const docSanp = await getDoc(doc(fdb, 'users', uid, 'registration', sportId))
            if (docSanp.exists()) {
                setOldData(docSanp.data())
            }
            return setLoading(false)
        }

        getSportData()

    }, [sportId, uid])

    if (loading)
        return <Loading
            loading={loading}
        />

    return (
        <>
            <Container
                maxWidth="lg"
                sx={{
                    padding: '24px',
                    minHeight: '100vh',
                    width: '100vw',
                }}
                style={{ width: '100vw', minWidth: '100vw' }}
            >
                {/* <Typography variant='v4'>{sportId}</Typography>
                <Typography>{sportsData.find(sport => (sport.title === sportId)).description}</Typography> */}

                {/* <RegistrationForm /> */}
                <WorldRecordReg oldData={oldData} resetOldData={() => setOldData({})} />
            </Container >
        </>
    )
}

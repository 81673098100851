import React, { useRef, useState } from 'react'
import './_flipbutton.scss'

export default function FlipButton({
    btntext = 'Click Me',
    onClick,
    BackComp,
    yesbutton = true,
    nobutton = true,
    isopen = false,
    style = {},
    children,
}) {
    const btnRef = useRef(null);
    const [isOpen, setIsOpen] = useState(isopen)

    const handleBtnFrontClick = (event) => {
        const btn = btnRef.current;
        const mx = event.clientX - btn.offsetLeft;
        const my = event.clientY - btn.offsetTop;

        const w = btn.offsetWidth;
        const h = btn.offsetHeight;

        const directions = [
            { id: 'top', x: w / 2, y: 0 },
            { id: 'right', x: w, y: h / 2 },
            { id: 'bottom', x: w / 2, y: h },
            { id: 'left', x: 0, y: h / 2 },
        ];

        directions.sort((a, b) => {
            return distance(mx, my, a.x, a.y) - distance(mx, my, b.x, b.y);
        });

        btn.setAttribute('data-direction', directions.shift().id);
        setIsOpen(true)
    };

    const distance = (x1, y1, x2, y2) => {
        const dx = x1 - x2;
        const dy = y1 - y2;
        return Math.sqrt(dx * dx + dy * dy);
    };

    const onClickLocal = (btn) => {
        setIsOpen(false)
        if (onClick)
            onClick(btn)
    }

    return (
        <div className={`flip-btn ${isOpen ? 'is-open' : ''}`} ref={btnRef} style={style}>
            <div className="flip-btn-back">
                {
                    BackComp ? <BackComp /> : ''
                }
                {
                    children ? children : ''
                }
                {
                    yesbutton ?
                        <button className="yes" onClick={() => onClickLocal({ btntext: 'Yes' })}>Yes</button>
                        : ''
                }
                {
                    nobutton ?
                        <button className="no" onClick={() => onClickLocal({ btntext: 'No' })}>No</button>
                        : ''
                }
            </div>
            <div className="flip-btn-front" onClick={handleBtnFrontClick}>{btntext}</div>
        </div>
    )
}

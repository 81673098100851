import { selectorFamily } from "recoil";
import { allSportsDataState } from "./atom";
import { registrationDefaultData, registrationFields, registrationValidationSchema } from "../../components/Events/defaultData";

export const sportDataState = selectorFamily({
    key: 'sportDataState',
    get: (sportid) => ({ get }) => {
        if (!sportid) return {}
        const allSports = get(allSportsDataState)
        const sport = allSports.find(sp => sp.title === sportid)
        if (!sport) return {}
        return sport
    }
})

export const sportRegiDataState = selectorFamily({
    key: 'sportRegiDataState',
    get: (sportid) => ({ get }) => {
        const defaultData = [...registrationDefaultData]
        const defaultFields = [...registrationFields]
        const defaultSchema = [...registrationValidationSchema]

        if (sportid)
            return {
                defaultData, defaultFields, defaultSchema
            }
    },
})

import { Paper, Typography } from '@mui/material'
import React from 'react'

export default function Organizer() {
    return (
        <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h5" gutterBottom>
                    Meet the Officials
                </Typography>
                {/* Images and names of officials */}
                <iframe src='https://youthwing.tnevysya.com/' width={'100%'} height={'500px'} />
            </Paper>
        </Paper>
    )
}

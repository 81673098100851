import React from 'react'
import './JumpLoader.css'

export default function JumpLoader() {
    return (
        <div className="jump-wrapper">
            <div className="jump-circle"></div>
            <div className="jump-circle"></div>
            <div className="jump-circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <span>Loading</span>
        </div>
    )
}

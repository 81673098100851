import React from 'react'
import { useResetRecoilState, useSetRecoilState } from 'recoil'
import { authUserState, isSportRegisterState } from '../../Recoil/Auth/atom'
import { fauth, fdb } from '../../Utils/Firebase/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'

export default function GetAuth() {
    const setAuthUser = useSetRecoilState(authUserState)
    const resetAuthUser = useResetRecoilState(authUserState)

    const setSportRegister = useSetRecoilState(isSportRegisterState)
    const resetSoprtRegister = useResetRecoilState(isSportRegisterState)

    React.useEffect(() => {
        const getAuth = () => {

            onAuthStateChanged(fauth, user => {
                if (user) {
                    setAuthUser(JSON.parse(JSON.stringify(user)))
                    // window.localStorage.removeItem('islogin')
                    return;
                }
                // const islogin = window.localStorage.getItem('islogin')
                // if (islogin) setLoginModal(true)
                return resetAuthUser()
            })
        }

        const getSportRegister = async () => {
            const isSportAllowed = getDoc(doc(fdb, 'admin', 'regist'))
            if ((await isSportAllowed).exists())
                return setSportRegister((await isSportAllowed).data().on)
        }

        getAuth()
        getSportRegister()

        return () => {
            resetAuthUser()
            resetSoprtRegister()
        }

    }, [])

    return ''
}

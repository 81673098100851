import { toast } from 'react-toastify'

export default function notify(type = 's', message = '', {
    position = 'top-center',
    autoClose = '5000',
    hideProgressBar = false,
    closeOnClick = true,
    pauseOnHover = true,
    draggable = true,
    progress = 0,
} = {
        autoClose: 5000,
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0
    }
) {
    const toastSettings = {
        position,
        autoClose,
        hideProgressBar,
        closeOnClick,
        pauseOnHover,
        draggable,
        progress,
    }

    switch (type) {
        case 's': return toast.success(message, { ...toastSettings })
        case 'e': return toast.error(message, { ...toastSettings })
        case 'i': return toast.info(message, { ...toastSettings })
        case 'w': return toast.warning(message, { ...toastSettings })
        case 'o':
            if (navigator.onLine)
                return toast.success(message, { ...toastSettings })
            return toast.warning(message, { ...toastSettings })
        case 'net': return toast.warning('Internet Connection Required. ' + message, { ...toastSettings })
        default: return toast(message, { ...toastSettings })
    }
}
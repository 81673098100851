import React, { useEffect, useState } from 'react'
import './App.css'
import { ParallaxProvider } from 'react-scroll-parallax'
import MobileNavigation from './MobileNavigation'
import AppMain from './AppMain'
import { BrowserRouter } from 'react-router-dom'
import AppNav from './AppNav'
import { Box, CssBaseline, Fab, Fade, ThemeProvider, createTheme, useScrollTrigger } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GetAuth from '../Auth/GetAuth'
import Loading from '../../Utils/CustomComponents/Loading/Loading'
import { useRecoilValue } from 'recoil'
import { themeState } from '../../Recoil/Auth/atom'
import 'react-responsive-modal/styles.css';

function FirstLoading() {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const timeoutid = setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])

    return <Loading type={0} loading={loading} />
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});


function ScrollTop(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Add smooth behavior for a smooth scroll
        });
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: '10%', right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
}

export default function App() {
    const theme = useRecoilValue(themeState)

    return (
        <ThemeProvider theme={theme ? darkTheme : lightTheme}>
            <CssBaseline />
            <ParallaxProvider>
                {/* <FirstLoading /> */}
                <GetAuth />
                <BrowserRouter>
                    <MobileNavigation />
                    <AppNav />
                    <AppMain />
                    <ScrollTop>
                        <Fab size="small" aria-label="scroll back to top" sx={{ marginBottom: '20%' }}>
                            <KeyboardArrowUpIcon />
                        </Fab>
                    </ScrollTop>
                </BrowserRouter>
            </ParallaxProvider>
        </ThemeProvider>
    )
}

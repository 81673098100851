import React from 'react'
import FlipButton from '../../Utils/CustomComponents/FlipButton/FlipButton'
import useAuth from '../Auth/useAuth'
import { Avatar, Box, Button, Container, Paper, Typography } from '@mui/material'
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authUserState } from '../../Recoil/Auth/atom';

function AccountLoggedIn() {
    const { logout } = useAuth()
    const user = useRecoilValue(authUserState)

    const navigate = useNavigate()

    return (
        <Box textAlign="center">
            <Avatar
                alt={user.displayName}
                src={user.photoURL}
                sx={{
                    width: 100,
                    height: 100,
                    margin: 'auto'
                    // marginBottom: theme.spacing(2),
                }}
            />
            <Typography variant="h5" gutterBottom>
                Welcome, {user.displayName}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Email: {user.email}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                <Button onClick={() => (navigate('/viewregisteration'))}>View Your Registeration</Button>
            </Typography>
            {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignOut}
                    className={classes.button}
                >
                    Sign Out
                </Button> */}
            <Box sx={{ margin: 'auto' }}>
                <FlipButton
                    btntext='Logout'
                    onClick={({ btntext = '' }) => {
                        return (btntext.toLowerCase() === 'yes') ? logout() : ''
                    }}
                    style={{ margin: 'auto' }}
                >
                    <p>Are You Sure Want to Logout ?</p>
                </FlipButton>
            </Box>
        </Box>
    )
}

function AccountLogin() {
    const navigate = useNavigate()
    return (
        <Box textAlign="center">
            <Avatar
                alt={'Guest'}
                src={''}
                sx={{
                    width: 100,
                    height: 100,
                    margin: 'auto'
                    // marginBottom: theme.spacing(2),
                }}
            />
            <Typography variant="h5" gutterBottom>
                Welcome, Guest
            </Typography>
            {/* <Typography variant="subtitle1" gutterBottom>
            Email: {user.email}
        </Typography> */}
            <Button variant='contained' size='large' endIcon={<LoginSharpIcon />} onClick={() => navigate('/login')}>Login</Button>
        </Box>
    )
}

export default function Account() {
    const { logout, isLoggedIn, authUser } = useAuth()

    return (
        <Container
            sx={{
                // background: 'linear-gradient(to right, #a2ab58,#636363)',
                minHeight: '100vh'
            }}
        >
            <div
                // elevation={3}
                style={{
                    padding: '20px',
                    background: 'transparent',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: '75vw',
                    minHeight: '75vh'
                }}
            >
                {
                    isLoggedIn ?
                        <AccountLoggedIn />
                        :
                        <AccountLogin />
                }
            </div>
        </Container>
    )
}

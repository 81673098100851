import React, { useState } from 'react'
import { Typography, Button, Container, Paper, Dialog, DialogTitle, DialogActions, DialogContent, Box } from '@mui/material';
import CreateFields from '../Account/CreateFields';
import { useFormik } from 'formik'
import * as yup from 'yup'
import { doc, setDoc } from 'firebase/firestore'
import { fdb } from '../../Utils/Firebase/firebase';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}

const ReceivePayment = ({ close }) => {
    const [showQR, setShowQR] = useState(false)

    const onSubmit = async (values) => {
        const docid = values.mobile + ''
        await setDoc(doc(fdb, "sponsor", docid),
            {
                [Number(new Date()).toString(36)]: {
                    name: values.name,
                    ts: new Date(),
                    district: values.district,
                    participent: values.participent
                },
                mobile: values.mobile
            },
            { merge: true }
        );

        setShowQR(true)
        // close()
    }

    const formik = useFormik({
        initialValues: { name: '', mobile: '', district: '', participent: '' },
        validationSchema: yup.object({
            name: yup.string().required('Enter Name'),
            mobile: yup.number().required('Enter Mobile').max(9999999999, 'Enter Valid Mobile Number').min(6000000000, 'Enter Valid Mobile Number'),
            district: yup.string().required('Select District')
        }),
        onSubmit: onSubmit,
    })

    const districts = [
        'Ariyalur', 'Chengalpattu', 'Chennai', 'Coimbatore', 'Cuddalore', 'Dharmapuri', 'Dindigul', 'Erode', 'Kallakurichi', 'Kancheepuram', 'Karur',
        'Krishnagiri', 'Madurai', 'Mayiladuthurai', 'Nagapattinam', 'Kanniyakumari', 'Namakkal', 'Perambalur', 'Pudukottai', 'Ramanathapuram', 'Ranipet',
        'Salem', 'Sivagangai', 'Tenkasi', 'Thanjavur', 'Theni', 'Thiruvallur', 'Thiruvarur', 'Thoothukudi', 'Trichirappalli', 'Thirunelveli', 'Tirupathur',
        'Tiruppur', 'Tiruvannamalai', 'Nilgirils', 'Vellore', 'Viluppuram', 'Virudhunagar', 'Karaikal', 'Pondicherry'
    ]

    const upiURL = `upi://pay?pa=broadwayfinancialservicecentre@sbi&pn=VysyaOlympiks&cu=INR&am=500.00&tn=${formik.values.mobile + ' - ' + formik.values.district.substring(0, 4)}`

    const shareScreenShot = () => {
        const senderName = (formik.values.name || '').trim();
        const senderMobile = ((formik.values.mobile || '') + '').trim();
        const senderDistrict = (formik.values.district || '').trim();

        const message = `Hi Vysya Olympiks Team 🎉, \n
*Contribution Details:*\n
*Donor Name: ${senderName}*
*Contact Number: ${senderMobile}*
*Residing District: ${senderDistrict}*\n


I am pleased to inform you that I have made a contribution of *Rs. 500* in support of a participant. This is a humble effort to contribute to the success of our event and the overall success of the Vysya Olympics. Let's continue to rally behind our participants and showcase the strength of our community. 🏅 

#VysyaOlympics
#VysyaOlympics2023

Here is my attached screenshot.

Best Regards,
${senderName}
`;

        const replayURL = `https://wa.me/+91${'9677548636'}?text=${encodeURIComponent(message)}`
        window.open(replayURL, '_blank')
    }

    return (
        <>
            <DialogTitle>{showQR ? 'Scan To Sponsor' : 'Enter Your Information'}</DialogTitle>
            <DialogContent>
                {
                    showQR ?
                        <div className="profile-photo-container">
                            <Typography variant="body1" textAlign={'center'}>
                                <Button onClick={() => window.open(upiURL, '_blank')}>Click Here</Button> to Sponsor.
                                <br />
                                or
                                <br />
                                Scan QR Code
                            </Typography>
                            <div className="profile-photo" style={{ width: '160px', height: '160px', background: 'lightgray', margin: '0 auto' }}>
                                <img src={`https://api.qrserver.com/v1/create-qr-code/?size=450x450&data=${encodeURIComponent(upiURL)}`} alt='Scan-Me'
                                    style={{ width: '160px', }}
                                />
                            </div>
                        </div>
                        :
                        <CreateFields
                            fields={[
                                { name: 'name', fprops: { label: 'Name' } },
                                { name: 'mobile', fprops: { label: 'Mobile', placeholder: 'WhatsApp Number', type: 'number' } },
                                { name: 'district', fprops: { label: 'District', options: districts }, field: 'select' },
                                { name: 'participent', fprops: { label: 'Participent Details (optional)', placeholder: 'For my son (or) Anyone' } },
                            ]}
                            formik={formik}
                        />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="error" variant='outlined'>
                    {showQR ? 'Close' : 'Cancel'}
                </Button>
                <Button onClick={showQR ? shareScreenShot : formik.submitForm} color="success" variant='contained'>
                    {showQR ? 'Share Screnshot' : 'Get Unique QR'}
                </Button>
            </DialogActions>
        </>
    )
}

export default function Sponsor() {
    const [sponsor, setSponsor] = useState(false)

    return (
        <Container maxWidth="md">
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', textAlign: 'center' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    {/* Vysya Olympics - Empowering Dreams */}
                    Sponsor a Vysya Olympian
                </Typography>
                <Typography paragraph>
                    Dear Vysya's,
                </Typography>

                <Typography variant="h5">How You Can Make a Difference</Typography>

                <Typography paragraph>
                    In the spirit of heartfelt connection, we invite you to join our special initiative – <span style={{ color: '#4CAF50', fontWeight: 'bolder' }}>"Support a Vysya Olympian."</span>
                    With a contribution of just 500 rupees,
                    you have the power to directly sponsor the participation of a cherished olympian.
                    Your generous donation will embrace various expenses, including training, equipment, and other essential costs.
                    Whether it's your son, daughter, grandson, granddaughter, a dear friend, or any other participant (anyone or even unknown) in the event,
                    your support carries a deep sentiment, fostering dreams and nurturing the journey of those we hold close.
                    {/* We are launching a special initiative – <span style={{ color: '#4CAF50', fontWeight: 'bolder' }}>"Sponsor a Vysya Olympian."</span> By contributing just 500 rupees, you can directly sponsor the participation of one player in their chosen sport. Your generous donation will cover various expenses, including training, equipment, and other essential costs. */}
                </Typography>

                {/* <Typography variant="h5" textAlign={'center'}>How to Donate</Typography> */}
                <Button variant="contained" color="primary" onClick={() => setSponsor(true)} >
                    Sponsor a Vysya Olympian
                </Button>

                <Dialog
                    open={sponsor}
                    onClose={() => setSponsor(false)}
                    maxWidth='md'
                    fullWidth
                    sx={{ minHeight: '75vh' }}
                >
                    {/* <Box sx={{ ...modalStyle, width: '80%', height: '80%', overflow: 'scroll' }}> */}
                    <ReceivePayment close={() => setSponsor(false)} />
                    {/* </Box> */}
                </Dialog>

            </Paper>
        </Container>
    );

}

import React, { useState } from 'react'
import { Card, CardContent, Typography, Grid, CardMedia, CardActions, Button, CardActionArea, Container, Paper, Snackbar, Alert, Modal, List, ListItem, ListItemText, Box } from '@mui/material';
import athleticsImg from './athletic2.jpg'
import chessImg from './chess3.jpg'
import carromImg from './carrom.jpg'
import shuttleImg from './shuttle.jpg'
import swimmingImg from './swimming.jpg'
import yogoImg from './yoga2.jpg'
import silambamImg from './silambam.jpg'
import skatingImg from './skating.jpg'
import kabadiImg from './kabadi.jpg'
import startsSoon from './startsSoon.jpg'
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isForTesting } from '../../Recoil/Auth/selector';
import CountdownCircle from '../../Utils/CustomComponents/Countdown/CountdownCircle';
import { allSportsDataState, generalTACState } from '../../Recoil/Sport/atom';
import TACModal from './TACModal';
import AgeGroups from './AgeGroups';
import { isSportRegisterState } from '../../Recoil/Auth/atom';
import worldrecordlogo from './worldrecord.webp'
// import worldrecordlogo from './worldrecord.jpg'
import vyoLogo from '../../Images/vyo2023-tb.png'

function GeneralTAC() {

    const termsAndConditions = useRecoilValue(generalTACState)

    return (
        <div >
            {/* <div style={{ position: 'absolute', width: '80%', height: '80%' }}> */}
            {/* <Typography variant="h5" component="h2">
                Terms and Conditions
            </Typography> */}

            <List>
                {
                    termsAndConditions.map((term, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>{index + 1}. {term.title}: </span>
                                        {term.content}
                                    </div>
                                }
                            />
                        </ListItem>
                    ))
                }
            </List>
        </div>
    )
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}

const getTutorialVideoID = () => {
    return '9oNB1mEBUKY'
    const videoLinks = ['2FIa9w5hEo4', 'ln2CNhc-AM0']

    return videoLinks[Number(Math.floor(Math.random() * videoLinks.length))]
}

export default function Sports() {
    const navigate = useNavigate()
    const { isMaster, islocal } = useRecoilValue(isForTesting)
    const [registerationStartsSoon, setRegisterationStartsSoon] = useState(false)
    const [tAC, setTAC] = useState(false)
    const [ageGroups, setAgeGroups] = useState(false)
    const isAllowRegister = useRecoilValue(isSportRegisterState)

    const sportsData = useRecoilValue(allSportsDataState)

    const default_sportsData = [
        {
            title: 'Athletics',
            img: athleticsImg,
            description: 'Join us for exciting track and field events.',
        },
        {
            title: 'Chess',
            img: chessImg,
            description: 'Test your strategic skills in intense chess matches.',
        },
        {
            title: 'Carrom',
            img: carromImg,
            description: 'Compete in a game of precision and tactics with carrom.',
        },
        {
            title: 'Shuttle',
            img: shuttleImg,
            description: 'Experience fast-paced badminton matches in the shuttle event.',
        },
        {
            title: 'Swimming',
            img: swimmingImg,
            description: 'Make a splash and race in the swimming competition.',
        },
        {
            title: 'Yoga',
            img: yogoImg,
            description: 'Find your inner peace and flexibility in the yoga event.',
        },
        {
            title: 'Silambam',
            img: silambamImg,
            description: 'Witness the artistry of silambam in this unique event.',
        },
        {
            title: 'Skating',
            img: skatingImg,
            description: 'Rollerblade your way to victory in the skating competition.',
        },
        {
            title: 'Kabaddi',
            img: kabadiImg,
            description: 'Get ready for intense team action in the kabaddi tournament.',
        },
    ];

    const register = (title) => {
        if (isAllowRegister)
            return navigate('/sports/registeration/' + title)
        if (isMaster)
            return navigate('/sports/registeration/' + title)
        setRegisterationStartsSoon(true)
    }

    const registerWorldRecord = () => {
        if (isAllowRegister)
            return navigate('/sports/worldregisteration/' + 'worldrecord')
        if (isMaster)
            return navigate('/sports/worldregisteration/' + 'worldrecord')
        setRegisterationStartsSoon(true)
    }

    return (
        <Container
            // maxWidth="lg"
            maxWidth="lg"
            sx={{
                // background: '#3E5151',  /* fallback for old browsers */
                // background: '-webkit-linear-gradient(to right, #DECBA4, #3E5151)',  /* Chrome 10-25, Safari 5.1-6 */
                // background: 'linear-gradient(to right, #DECBA4, #3E5151)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                padding: '24px',
                // width: '100vw',
                // minWidth: '100vw',
                // maxWidth: '100vw'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <img src={vyoLogo} alt='vy-olympiks-2023'
                    style={{ width: '50%', }} />
            </Box>
            {/* <img src={vyoLogo} alt='vysya-olympiks-2023-logo' style={{ width: '50%' }} /> */}
            <Typography variant="h4" gutterBottom >
                Sports Events
            </Typography>
            <Typography variant="body1" paragraph >
                Here at Vysya Olympiks 2023, we are proud to offer a wide range of sports for everyone to enjoy.
                Explore the details of each sport below and get ready for an unforgettable experience.
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                {/* <div className="video-responsive"> */}
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${getTutorialVideoID()}?autoplay=${islocal ? 0 : 1}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Vysya Olympiks Registration Guide"
                />
                {/* </div> */}
            </Box>
            <br />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Card sx={{ maxWidth: '450px', }}>
                    <CardActionArea onClick={() => registerWorldRecord('worldrecord')}>
                        <CardMedia
                            sx={{ height: '340px', zIndex: -1 }}
                            image={worldrecordlogo}
                        />
                        <CardContent>
                            <Typography variant="h5" component="div">
                                WORLD RECORD
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Unlock the extraordinary! Dive into the chance to make history by participating in our World Record attempt. Fill out the registration form and be a vital part of a global phenomenon. Your unique contribution awaits—seize the opportunity and join us on this exhilarating journey to create something extraordinary together!
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" onClick={() => registerWorldRecord('worldrecord')} >Register</Button>
                        {/* <Button size="small" onClick={() => setAgeGroups(sport.title)} >Age Groups</Button>
                    <Button size="small" onClick={() => setTAC(sport.title)}>T & C</Button> */}
                    </CardActions>
                </Card>
            </Box>
            <br />
            {/* <Typography variant="h4" sx={{ textAlign: 'center' }} >
                Registration Starts In
            </Typography>
            <CountdownCircle /> */}
            <Grid container spacing={3}>
                {sportsData.map((sport, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card>
                            <CardActionArea
                            // onClick={() => register(sport.title)}
                            >
                                <CardMedia
                                    sx={{ height: '140px', zIndex: -1 }}
                                    image={sport.img}
                                />
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {sport.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {sport.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                {/* <Button size="small" onClick={() => register(sport.title)} >Register</Button> */}
                                <Button size="small" onClick={() => setAgeGroups(sport.title)} >Age Groups</Button>
                                <Button size="small" onClick={() => setTAC(sport.title)}>T & C</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {/* <Snackbar
                open={true}
                // autoHideDuration={6000}
                // onClose={() => setValError({ open: false, message: '' })}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <Alert severity="warning" sx={{ width: '100%' }}>
                    Registeration Starts at Vijaya Dasami
                </Alert>
            </Snackbar> */}

            <Modal
                open={registerationStartsSoon}
                onClose={() => setRegisterationStartsSoon(false)}
            >
                <img src={startsSoon} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '75%', maxHeight: '75%' }} />
            </Modal>
            <TACModal sportid={tAC} open={!!tAC} onClose={() => setTAC(false)} />
            <AgeGroups sportid={ageGroups} open={!!ageGroups} onClose={() => setAgeGroups(false)} />
            {/* <Modal
                open={tAC}
                onClose={() => setTAC(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...modalStyle, width: '80%', height: '80%', overflow: 'scroll' }}>
                    <h2 id="child-modal-title">Terms & Conditions</h2>
                    <GeneralTAC />
                    <Button onClick={() => setTAC(false)}>Close</Button>
                </Box>
            </Modal> */}
        </Container>
    );
};

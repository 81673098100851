import { Box, Paper, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'

import para1 from '../../Images/parallel1.jpg'
import para2 from '../../Images/parallel2.png'
import sportFire from '../../Images/sport-hand.png'
import { useTheme } from '@emotion/react'

export default function Welcome() {
    const muitheme = useTheme()
    const isMobile = useMediaQuery(muitheme.breakpoints.down('sm'));


    return (
        <Paper
            sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ParallaxBanner
                style={{ height: '100%' }}
                layers={[
                    {
                        image: para1,
                        speed: -20,
                    },
                    {
                        children: (
                            <Box sx={{ position: 'absolute', top: '30%', width: '100%' }}>
                                <Typography
                                    variant="h4"
                                    gutterBottom
                                    sx={{
                                        width: '90vw',
                                        margin: '0 auto',
                                        textAlign: 'center',
                                        background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        background: '#C9FFBF',  /* fallback for old browsers */
                                        background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                        background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    Welcome back to
                                </Typography>
                                <Typography
                                    variant="h3"
                                    gutterBottom
                                    sx={{
                                        width: '90vw',
                                        margin: '0 auto',
                                        textAlign: 'center',
                                        background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        background: '#C9FFBF',  /* fallback for old browsers */
                                        background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                        background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    Vysya Olympiks
                                </Typography>
                                <Typography
                                    variant="h4"
                                    gutterBottom
                                    sx={{
                                        width: '90vw',
                                        margin: '0 auto',
                                        textAlign: 'center',
                                        background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        background: '#C9FFBF',  /* fallback for old browsers */
                                        background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                        background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    2023
                                </Typography>
                                <Typography
                                    gutterBottom
                                    sx={{
                                        width: '90vw',
                                        margin: '0 auto',
                                        textAlign: 'center',
                                        background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        background: '#C9FFBF',  /* fallback for old browsers */
                                        background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                        background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    Powered by the Vision and Spirit of TNAVMSYW
                                </Typography>
                                {
                                    isMobile ? '' :
                                        <Typography
                                            variant="h4"
                                            gutterBottom
                                            sx={{
                                                width: '90vw',
                                                margin: '0 auto',
                                                textAlign: 'center',
                                                background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                                WebkitBackgroundClip: 'text',
                                                WebkitTextFillColor: 'transparent',
                                                background: '#C9FFBF',  /* fallback for old browsers */
                                                background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                                background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            @<br />
                                            Thiruvannamalai
                                        </Typography>
                                }
                            </Box>
                        ),
                        speed: 15,
                    },
                    {
                        image: sportFire,
                        speed: 70,
                        rotate: [60, 0],
                        style: { width: '140px', height: '140px', top: '60%', left: '60%' },
                        scaleX: [-1, -1],
                        translateY: ['40%', 0]
                    },
                    {
                        image: para2,
                        speed: -10,
                    },
                    {
                        speed: -20,
                        children: (
                            <Box sx={{ position: 'absolute', bottom: '30%', width: '100%' }}>
                                {
                                    isMobile ?
                                        <Typography
                                            variant="h4"
                                            gutterBottom
                                            sx={{
                                                width: '90vw',
                                                margin: '0 auto',
                                                textAlign: 'center',
                                                background: '-webkit-linear-gradient(45deg, #00ff95,#09009f  80%)',
                                                WebkitBackgroundClip: 'text',
                                                WebkitTextFillColor: 'transparent',
                                                background: '#C9FFBF',  /* fallback for old browsers */
                                                background: '-webkit-linear-gradient(to left, #FFAFBD, #C9FFBF)',  /* Chrome 10-25, Safari 5.1-6 */
                                                background: 'linear-gradient(to left, #FFAFBD, #C9FFBF)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                                fontWeight: 'bolder',
                                            }}
                                        >
                                            @<br />
                                            Thiruvannamalai
                                        </Typography>
                                        : ''
                                }
                            </Box>
                        )
                    },
                ]}
            >
            </ParallaxBanner>
        </Paper>
    )
}

import { BottomNavigation, BottomNavigationAction, Paper, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import PersonIcon from '@mui/icons-material/Person';
import { useTheme } from '@mui/material';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

export default function MobileNavigation() {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [tab, setTab] = useState('')

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const firstPathSegment = pathname.split('/')[1];
        if (pathname === '/')
            setTab('home')
        if (tab !== firstPathSegment)
            setTab(firstPathSegment)
    }, [pathname])

    const onChange = (_, val = '') => {
        setTab(val)
    }

    return (
        <nav>
            <Paper
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    background: 'hsl(0 0% 0%)',
                    zIndex: '1000',
                    display: { xs: 'unset', md: 'none' }
                }}
                elevation={3}
            >
                {
                    (isMobile) ?
                        <BottomNavigation
                            value={tab}
                            onChange={onChange}
                        // sx={{ background: 'rgb(25,25,25)' }}
                        >
                            <BottomNavigationAction
                                label='Home'
                                icon={<HomeIcon />}
                                value={'home'}
                                onClick={() => navigate('/home')}
                            // sx={{ color: '#f1f1f1' }}
                            />
                            <BottomNavigationAction
                                label='Sports'
                                icon={<SportsVolleyballIcon />}
                                value={'sports'}
                                onClick={() => navigate('/sports')}
                            // sx={{ color: '#f1f1f1' }}
                            />
                            <BottomNavigationAction
                                label='Account'
                                icon={<PersonIcon />}
                                value={'account'}
                                onClick={() => navigate('/account')}
                            // sx={{ color: '#f1f1f1' }}
                            />
                            <BottomNavigationAction
                                label='Sponsor'
                                icon={<VolunteerActivismIcon />}
                                value={'sponsor'}
                                onClick={() => navigate('/sponsor')}
                            // sx={{ color: '#f1f1f1' }}
                            />
                        </BottomNavigation>
                        : ''
                }
            </Paper>
        </nav>
    )
}

import React, { useEffect } from 'react'
import { fauth } from '../../Utils/Firebase/firebase'
import * as firebaseui from 'firebaseui'
import { GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider } from 'firebase/auth'
import useAuth from './useAuth'
import { Avatar, Box, Button, Container, CssBaseline, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import SportsTennisSharpIcon from '@mui/icons-material/SportsTennisSharp';
import Account from '../Account/Account'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function LoginUI() {

    const navigate = useNavigate()

    useEffect(() => {
        let fui = new firebaseui.auth.AuthUI(fauth)

        const uiConfig = {
            signInOptions: [
                {
                    provider: GoogleAuthProvider.PROVIDER_ID,
                    scopes: [
                        // 'https://www.googleapis.com/auth/contacts.readonly', //contacts
                        'https://www.googleapis.com/auth/user.birthday.read', // Date of Birth
                        'https://www.googleapis.com/auth/user.gender.read', // Gender
                        'https://www.googleapis.com/auth/user.phonenumbers.read', // Phone Number
                    ],
                    customParameters: {
                        // Forces account selection even when one account
                        // is available.
                        prompt: 'select_account'
                    }

                },
                // {
                //     provider: FacebookAuthProvider.PROVIDER_ID,
                //     scopes: [
                //         'public_profile',
                //         'email',
                //         'user_likes',
                //         'user_friends'
                //     ],
                //     customParameters: {
                //         // Forces password re-entry.
                //         auth_type: 'reauthenticate'
                //     }
                // },
                {
                    provider: EmailAuthProvider.PROVIDER_ID,
                    signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                },
                // { provider: PhoneAuthProvider.PROVIDER_ID, }
            ],
            signInSuccessUrl: window.location.href.replace('authenticate=true', ''),
        }

        fui.start('#firebaseui-auth-container', uiConfig)
        window.localStorage.setItem('islogin', true)

        return () => {
            // fui.reset()
            if (fui)
                fui.delete()
        }
    }, [])

    return <>
        <Container maxWidth="lg" style={{ lineHeight: 'normal' }}>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h5" gutterBottom>
                    Sign In
                </Typography>
                <Typography variant="body1">
                    Welcome to Vysya Olympiks 2023! To register for the event and access your registered data, please sign in with your account.
                </Typography>
                <Box sx={{ mt: 1, position: 'relative', minHeight: '200px', width: '100%' }}>
                    <div
                        id='firebaseui-auth-container'
                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    />
                </Box>
            </Box>

            <Typography variant="body1" align='center' sx={{ mt: 2 }}>
                Signing in allows you to:
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" >
                Register for Vysya Olympiks 2023.<br />
                View and manage your registration details.<br />
                Access exclusive event information and updates.
                {/* Add more points as needed */}
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
                {'Copyright © '}
                Vysya Olympiks.
                {' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Container>

        {/* <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
            <Button
                // component={Link} // Use Link component for page redirection
                // to="/home" // Replace with the actual path to your home page
                variant="outlined"
                color="secondary"
                onClick={() => navigate('/')}
                startIcon={<HomeSharpIcon />}
                sx={{ margin: '10px' }}
            >
                Home Page
            </Button>
            <Button
                // component={Link} // Use Link component for page redirection
                // to="/home" // Replace with the actual path to your home page
                variant="outlined"
                color="primary"
                onClick={() => navigate('/sports')}
                startIcon={<SportsTennisSharpIcon />}
                sx={{ margin: '10px' }}
            >
                Sports Page
            </Button>
        </Box> */}
    </>

}

export default function Login() {

    const { isLoggedIn } = useAuth()

    return (
        <>
            {/* <Paper
                sx={{
                    minHeight: '100vh',
                    background: 'linear-gradient(to right, #a2ab58,#636363)'
                }}
            > */}
            {
                isLoggedIn ?
                    <Account />
                    :
                    <LoginUI />
            }
            {/* </Paper> */}
        </>
    )
}

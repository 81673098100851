import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Home/Home'
import Sports from '../Sports/Sports'
import Account from '../Account/Account'
import Regiestration from '../Events/Regiestration'
import Login from '../Auth/Login'
import { Container, Paper, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material'
import useAuth from '../Auth/useAuth'
import RegisterationStartsSoon from '../Events/RegisterationStartsSoon'
import Loading from '../../Utils/CustomComponents/Loading/Loading'
import TimeCountdown from '../../Utils/CustomComponents/Countdown/TimeCountdown'
import { useRecoilValue } from 'recoil'
import { authUserState, isSportRegisterState } from '../../Recoil/Auth/atom'
import HomePage from '../Home/HomePage'
import ViewRegisteration from '../Account/ViewRegisteration'
import { isForTesting } from '../../Recoil/Auth/selector'
import TestAdmin from '../TestAdmin/TestAdmin'
import TestLocal from '../TestAdmin/TestLocal'
import Regiestration2 from '../Events/Registeration2'
import Gallery from '../Gallery/Gallery'
import WRPayment from '../WorldRecord/WRPayment'
import Sponsor from '../Sponsor/Sponsor'

const masterUID = {
    "t3R9AK8XEeW6gbweeUNWxbmvz4P2": 'tnevysya',
    "XyVT6ywDNNVuPle8nV2DkIyfKfp2": 'gk'
}

export default function AppMain() {
    const [loading, setLoading] = useState(true)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { isMaster } = useRecoilValue(isForTesting)

    const islocal = !!(process.env.NODE_ENV === 'development')

    const { uid } = useRecoilValue(authUserState)

    const isSportRegister = useRecoilValue(isSportRegisterState)

    const { isLoggedIn } = useAuth()

    useEffect(() => {
        const time = Math.floor(Math.random() * 1234) + 2000
        const timeoutid = setTimeout(() => {
            setLoading(false)
        }, time)

        return () => {
            clearTimeout(timeoutid)
        }
    }, [])

    return (
        <>
            <Loading type={0} loading={loading} />
            <Container
                component="main"
                maxWidth={false}
                style={{
                    // width: '100vw',
                    // overflow: 'hidden',
                    padding: 0,
                    opacity: (loading ? 0 : 'unset'),
                    transition: 'opacity 0.3s'
                }}
            // sx={{
            //     marginBottom: '56px',
            // }}
            >
                {/* <Paper
                    sx={isMobile ? {
                        marginBottom: '56px',
                    } : {}}
                    style={{
                        // padding: '0px',
                        // maxWidth: '100vw'
                        // paddingBottom: '24px'
                    }}
                > */}
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='home' element={<HomePage />} />
                    <Route path='gallery' element={<Gallery />} />
                    <Route path='sports' element={<Sports />} />
                    {/* <Route path='sports/registeration/:sportId' element={!isLoggedIn ? <Login /> : (((isMaster) || isSportRegister) ? <Regiestration /> : <RegisterationStartsSoon />)} /> */}
                    <Route path='sports/worldregisteration/:sportId' element={!isLoggedIn ? <Login /> : (((isMaster) || isSportRegister) ? <Regiestration2 /> : <RegisterationStartsSoon />)} />
                    <Route path='viewregisteration' element={!isLoggedIn ? <Login /> : <ViewRegisteration />} />
                    {/* <Route path='testadmin' element={(islocal || isMaster) ? <TestAdmin /> : <HomePage />} /> */}
                    <Route path='testlocal' element={(islocal) ? <TestLocal /> : <HomePage />} />
                    <Route path='account' element={<Account />} />
                    <Route path='login' element={<Login />} />
                    <Route path='countdown' element={<TimeCountdown />} />
                    <Route path='starts' element={<RegisterationStartsSoon />} />
                    <Route path='wrpay' element={<WRPayment />} />
                    <Route path='sponsor' element={<Sponsor />} />

                </Routes>
                {
                    isMobile ? <div style={{ height: '56px' }}></div> : ''
                }
            </Container>
        </>
    )
}

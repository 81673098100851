import React from 'react'
import Welcome from './Welcome'
import AboutPage from './AboutPage'
import CoutndownSection from './CoutndownSection'
import Organizer from './Organizer'
import Sponsors from './Sponsors'
import By from './By'

export default function HomePage() {
    return (
        <>
            <Welcome />
            <By />
            <CoutndownSection />
            <AboutPage />
            <Organizer />
            <Sponsors />
        </>
    )
}
